var Utilities = (function () {
  function memoize(func) {
    /// <summary>Cache calls to a function.</summary>
    /// <param name="func" type="Function">The pure function to be cached.</param>
    /// <returns type="Function">A wrapper function with caching enabled.</returns>

    var memo = {};

    return function () {
      var args = Array.prototype.slice.call(arguments);
      var hash = '';
      var index = args.length;

      while (index--) {
        var currentArg = args[index];
        hash += currentArg === Object(currentArg)
          ? JSON.stringify(currentArg)
          : currentArg;
      }

      return hash in memo
        ? memo[hash]
        : (memo[hash] = func.apply(this, args));
    };
  }

  function lazy(createFunc) {
    /// <summary>Provides support for lazy initialization.</summary>
    /// <param name="createFunc" type="Function">An initialization function.</param>
    /// <returns type="Object">An object with a getter named value. Accessing the getter for the first time will run the initialization function.</returns>

    var internal = null;

    return Object.defineProperty({}, 'value', {
      configurable: false,
      get: function () {
        if (internal == null)
          internal = createFunc();

        return internal;
      }
    });
  }

  function compose() {
    /// <summary>Compose multiple functions together into one. For example, two functions A -> B and B -> C will become one function A -> C.</summary>
    /// <param name="arguments" type="Function[]">The functions to compose.</param>
    /// <returns type="Function">The composed function.</returns>

    var functions = arguments;

    return function (value) {
      var result = value;

      for (var index = 0; index < functions.length; index++)
        result = functions[index].call(this, result);

      return result;
    };
  }

  var queryParameters = lazy(function () {
    var queryString = location.search.substring(1);
    var map = {};

    if (queryString == null || queryString === '')
      return map;

    var queryParams = queryString.split('&');

    for (var index = 0; index < queryParams.length; index++) {
      var parts = queryParams[index].split('=');
      var nameLowered = parts[0].toLowerCase();
      var value = parts[1];

      map[nameLowered] = value != null && value !== '' ? decodeURIComponent(value) : null;
    }

    return map;
  });

  function getQueryParameter(name) {
    return queryParameters.value[name.toLowerCase()] || null;
  }

  function parseShortenedLink(fbLink) {
    /// <summary>Parse an fbLink into specifier and selector.</summary>
    /// <param name="fbLink" type="string">The fbLink.</param>
    /// <returns type="Object">An object with signature { specifier: string, selector: string }.</returns>

    var fbLinkPieces = fbLink.split('seln');
    var specifier = fbLinkPieces[0].replace(/\./g, "\\");
    var selector = fbLinkPieces[1];

    return { specifier: specifier, selector: selector };
  }

  function parseFullSelString(fullSelString) {
    var individualSels = [];
    var parserState = [];

    for (var index = 0; index < fullSelString.length; index++) {
      var character = fullSelString.charAt(index);

      if (character === ',' && (parserState.indexOf('(') === -1 || parserState.indexOf(')') > -1)) {
        individualSels.push(parserState.join(''));
        parserState = [];

        continue;
      }

      parserState.push(character);
    }

    if (parserState.length > 0)
      individualSels.push(parserState.join(''));

    return individualSels;
  }

  function parseSelTree(selector) {
    /// <summary>Parses any type of selector into a tree structure.</summary>
    /// <param name="selector" type="string">A selector (i.e. fullSelString, surface sel).</param>
    /// <returns type="Array">An array with items of signature: type Tree = { sel: string, children: Tree[] } | { sel: string }.</returns>

    var parsed = [];
    var buffer = [];
    var treeLevel = [];

    for (var index = 0, length = selector.length; index < length; index++) {
      var character = selector[index];

      if (character === ',' || character === '(' || character === ')') {
        var branch = treeLevel.length > 0 ? treeLevel[treeLevel.length - 1] : null;

        if (buffer.length > 0) {
          var newBranch = { sel: buffer.join('') };
          buffer = [];

          if (branch == null)
            parsed.push(newBranch);
          else if (branch.children != null)
            branch.children.push(newBranch);
          else
            branch.children = [newBranch];

          if (character === '(')
            treeLevel.push(newBranch);
        }

        if (character === ')')
          treeLevel.pop();
      }
      else
        buffer.push(character);
    }

    if (buffer.length > 0)
      parsed.push({ sel: buffer.join('') });

    return parsed;
  }

  function parsePattern(selector) {
    var productIds = selector.replace(')', '').split(/\(|,/);
    var length = productIds.length;

    return {
      patternId: productIds[0],
      productIds: productIds.slice(1, length - 3),
      groutId: productIds[length - 3],
      groutWidthId: productIds[length - 2],
      rotationId: productIds[length - 1]
    };
  }

  function parsePatternProducts(selector) {
    var productIds = selector.replace(')', '').split(/\(|,/);

    return productIds.slice(1, productIds.length - 3);
  }

  function isSmallMobileDevice() {
    /// <summary>Determines whether the client is a tablet/mobile device with a screen resolution greater than or equal to iPhone 6.</summary>

    if (window.screen.width >= 375) // 375 is the width of the iPhone 6
      return false;

    var ua = navigator.userAgent;
    return (ua.match(/iPhone/i) && !ua.match(/iPad/i)) // Twitter iPad app built in browser has iPhone and iPad in user agent.
      || ua.match(/Opera Mini/i) || ua.match(/IEMobile/i)
      || (ua.match(/Android/i) && ua.match(/Mobile/i))
      || ua.match(/BlackBerry/i);
  }

  var raf = (window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.msRequestAnimationFrame
    || function (fn) { window.setTimeout(fn, 1); })
    .bind(window);

  return {
    getQueryParameter: getQueryParameter,
    parseShortenedLink: parseShortenedLink,
    parseFullSelString: parseFullSelString,
    parsePattern: parsePattern,
    parsePatternProducts: parsePatternProducts,
    memoize: memoize,
    lazy: lazy,
    compose: compose,
    isSmallMobileDevice: isSmallMobileDevice,
    raf: raf,
    parseSelTree: parseSelTree
  };
})();
