var $ = jQuery.noConflict(),
  CHAM = CHAM || {};

(function ($) {
  'use strict';

  var lazy = Utilities.lazy;
  var getQueryParameter = Utilities.getQueryParameter;
  var parseSelTree = Utilities.parseSelTree;

  var defaultZoom = {
    root: lazy(function () {
      return $('#deck-zoom-dialog');
    }),
    content: lazy(function () {
      return $('#deck-zoom-dialog .content').get(0);
    }),
    events: function () {
      var root = this.root.value;

      root.on('click', '.close', root.fadeOut.bind(root));
      root.on('click', function (e) {
        if ($(e.target).is(root))
          root.fadeOut();
      });
    },
    render: function (product, title) {
      var html = CHAM.templates.defaultZoom(product, title);

      this.content.value.innerHTML = html;
      this.root.value.fadeIn();
    }
  };

  var wideZoom = {
    root: lazy(function () {
      return $('#railing-zoom-dialog');
    }),
    content: lazy(function () {
      return $('#railing-zoom-dialog .content').get(0);
    }),
    events: function () {
      var root = this.root.value;

      root.on('click', '.close', root.fadeOut.bind(root));
      root.on('click', function (e) {
        if ($(e.target).is(root))
          root.fadeOut();
      });
    },
    render: function (product, title) {
      var descriptionItems = product.warranty != null && product.warranty !== '' ?
        product.warranty.split('|') :
        [];
      var html = CHAM.templates.wideZoom(product, title, descriptionItems);

      this.content.value.innerHTML = html;
      this.root.value.fadeIn();
    }
  };

  var productMenus = {
    'Decking': {
      events: function (root, surfaces, productLookup) {
        var onlySurface = surfaces[0];

        root.on('click', '.tab-link', function () {
          var self = $(this);
          var tabNumber = self.data('tab');

          self.addClass('active').siblings().removeClass('active');
          root.find('.tab').removeClass('active').filter('[data-tab="' + tabNumber + '"]').addClass('active');
        });

        root.on('click', '.product', function () {
          var self = $(this);
          if (self.hasClass('active'))
            return;

          var sel = self.data('sel');
          CHAM.menu.closeSurfaceGroup([onlySurface.specifiersurfacenumber]);
          CHAM.visualizer.apply(onlySurface.specifiersurfacenumber, sel);
          root.find('.product').removeClass('active').filter('[data-sel="' + sel + '"]').addClass('active');
        });

        root.on('click', '.zoomin', function (e) {
          e.stopPropagation(); // Prevent product from applying.
          var sel = $(this).data('sel');
          var product = productLookup[sel];
          var title = product.name + ' Decking Color and Grain Detail - ' + product.color;

          defaultZoom.render(product, title);
        });

        root.on('change', '.style-select', function () {
          var filterName = $(this).val();
          var wrapper = root.find('.deck-styles');
          var typeInfo = root.find('.decking-type-info').get(0);

          if (filterName === 'All') {
            typeInfo.innerHTML = '';
            wrapper.find('.product-group, .product').removeClass('hidden single-shown');
          } else {
            var deckingType = CHAM.DATA.deckingTypes[filterName];
            var infoHtml = deckingType != null ? CHAM.templates.deckTypeInfo(deckingType) : '';
            typeInfo.innerHTML = infoHtml;

            wrapper.find('.product-group').each(function () {
              var self = $(this);
              var visibleProducts = self.find('.product').addClass('hidden')
                .filter(function () {
                  var product = productLookup[$(this).data('sel')];
                  return product.name === filterName;
                })
                .removeClass('hidden');

              self.toggleClass('single-shown', visibleProducts.length > 0);
              self.toggleClass('hidden', visibleProducts.length === 0);
            });
          }
        });

        root.on('click', '.color-option', function () {
          var self = $(this);
          var filterName = self.data('filter');
          var deckColors = root.find('.deck-colors');

          self.addClass('active').siblings().removeClass('active');

          if (filterName === 'All')
            deckColors.find('.product-group, .product').removeClass('hidden');
          else
            deckColors.find('.product-group').each(function () {
              var self = $(this);
              var visibleProducts = self.find('.product').addClass('hidden')
                .filter(function () {
                  var product = productLookup[$(this).data('sel')];
                  return product.description === filterName;
                })
                .removeClass('hidden');

              self.toggleClass('hidden', visibleProducts.length === 0);
            });
        });
      },
      createModel: function (productData) {
        var deckProducts = productData.stain.products;
        var products = _(deckProducts)
          .sortBy(function (product) {
            return [product.sortOrder, product.color];
          })
          .groupBy(function (product) {
            return product.name;
          })
          .map(function (products, name) {
            return {
              name: name,
              products: products
            };
          })
          .sortBy(function (group) {
            var deckingType = CHAM.DATA.deckingTypes[group.name];
            return deckingType != null ? deckingType.sortOrder : 99;
          })
          .value();

        var productStyleTab = {
          filter: _(deckProducts)
            .uniqBy(function (product) {
              return product.name;
            })
            .map(function (product) {
              return product.name;
            })
            .sortBy(function (name) {
              var deckingType = CHAM.DATA.deckingTypes[name];
              return deckingType != null ? deckingType.sortOrder : 99;
            })
            .value(),
          products: products
        };

        var colorFamilyTab = {
          filter: _(deckProducts)
            .uniqBy(function (product) {
              return product.description;
            })
            .map(function (product) {
              var family = CHAM.DATA.colorFamilies[product.description];
              return family != null ?
                {
                  name: product.description,
                  hexColor: family.hexColor,
                  sortOrder: family.sortOrder
                } :
                {
                  name: product.description,
                  hexColor: null,
                  sortOrder: 99
                };
            })
            .sortBy(function (family) {
              return family.sortOrder;
            })
            .value(),
          products: products
        };

        return {
          productStyleTab: productStyleTab,
          colorFamilyTab: colorFamilyTab
        };
      },
      render: CHAM.templates.deckProducts
    },
    'Railing': {
      events: function (root, surfaces, productLookup) {
        var self = this;
        var surfaceLookup = _.keyBy(surfaces, function (surface) {
          return surface.name;
        });
        var surfaceNumbers = _.map(surfaces, function (surface) {
          return surface.specifiersurfacenumber;
        });
        var railingProducts = lazy(function () {
          return root.find('.railing-product');
        });

        var optionsProducts = lazy(function () {
          return _(root.find('.product'))
            .groupBy(function (element) {
              return $(element).data('surface-names');
            })
            .mapValues(function (elementGroup) {
              return $(elementGroup);
            })
            .value();
        });

        var stepData = lazy(function () {
          var additional = root.find('.railing-additional');
          var railSteps = root.find('.rail-step');
          var steps = _.map(root.find('.rail-link').get(), function (linkElement) {
            var node = $(linkElement);
            var stepNumber = node.data('step');
            var stepNode = railSteps.filter('[data-step="' + stepNumber + '"]');

            return {
              nodes: node.add(stepNode),
              number: stepNumber
            };
          });

          return {
            additionalOptions: additional,
            steps: steps
          };
        });

        root.on('click', '.rail-link', function () {
          var stepNumber = $(this).data('step');
          self.goToRailingStep(stepData.value, stepNumber);
        });

        root.on('click', '#railing-next', function () {
          self.goToRailingStep(stepData.value, 2);
        });

        root.on('click', '.zoomin', function (e) {
          e.stopPropagation(); // Prevent product from applying.
          var sel = $(this).data('sel');
          var product = productLookup[sel];
          var title = product.name + ' Railing Color and Detail - ' + product.color;

          wideZoom.render(product, title);
        });

        root.on('click', '.railing-product', function () {
          var clicked = $(this);
          if (clicked.hasClass('active'))
            return;

          var data = clicked.data();
          var product = productLookup[data.sel];
          var productHtml = CHAM.templates.railingCurrentProduct(product);

          railingProducts.value.removeClass('active');
          clicked.addClass('active');
          clicked.parents('.railing-options').find('.current-product').get(0).innerHTML = productHtml;
            console.log('click', '.railing-product', data.railingType, data);
          self.tryUpdateRailingTypeAndApply(data.sel, data.railingType, data.surfaceNames.split(','), surfaceLookup);
        });

        root.on('click', '.product', function () {
          var clicked = $(this);
          if (clicked.hasClass('active'))
            return;

          var data = clicked.data();
          var product = productLookup[data.sel];
          var productHtml = CHAM.templates.railingCurrentProduct(product);

          optionsProducts.value[data.surfaceNames].removeClass('active');
          clicked.addClass('active');
          clicked.parents('.additional-options').find('.current-product').get(0).innerHTML = productHtml;

          CHAM.menu.closeSurfaceGroup(surfaceNumbers);
          self.applyBySurfaceNames(data.sel, data.surfaceNames.split(','), surfaceLookup);
        });
      },
      tryUpdateRailingTypeAndApply: function (selector, railingType, surfaceNames, surfaceLookup) {
        // Warning: lots of shared mutables used in this func.
        var currentData = CHAM.visualizer.getCurrentData();
        var currentScene = _.find(CHAM.DATA.scenes, function (scene) {
          return scene.specifier === currentData.specifier;
        });

        if (railingType === 'Symmetry Signature' || railingType.indexOf('CountrySide') > -1) {
          railingType = 'Symmetry';
        }

        if (railingType.indexOf('Classic') > -1) {
          railingType = 'Classic Square';
        }

        if (railingType.indexOf('Regency') > -1) { //round or square
          railingType = 'Regency Square';
        }

        if (railingType.indexOf('Enclave') > -1) {
          railingType = 'Enclave Square';
        }

        if (railingType.indexOf('Deluxe') > -1) {
          railingType = 'Deluxe Square';
        }

        if (railingType.indexOf('HavenView') > -1) {
          railingType = 'Symmetry';
        }

        if (railingType.indexOf('Brio Rhythm') > -1) { //round or square
          railingType = 'Brio Rhythm Square'; //Brio Rhythm Square, or Brio Rhythm Round
          }
          if (railingType.indexOf('Brio') > -1) { //round or square
              railingType = 'Brio Rhythm Square'; //Brio Rhythm Square, or Brio Rhythm Round
          }
          if (railingType.indexOf('CitySide') > -1) { //round or square
              railingType = 'Havenview CitySide Square'; //Brio Rhythm Square, or Brio Rhythm Round
          }

        if (currentScene.color === railingType) {
          this.applyBySurfaceNames(selector, surfaceNames, surfaceLookup);
          return;
        }

        var currentSurfaceSels = Viz.sel.parseToSurfaceArray(currentData.selector);
        var newScene = _.find(CHAM.DATA.scenes, function (scene) {
          return scene.name === currentScene.name && scene.color === railingType;
        });
        //console.log('newScene', newScene, railingType);
        var newFullSelString = _.zipWith(currentData.surfaces, currentSurfaceSels, function (surface, currentSelector) {
          if (surfaceNames.indexOf(surface.name) > -1)
            return selector;

          return surfaceLookup.hasOwnProperty(surface.name) ?
            '-1' :
            currentSelector;
        }).join(',');
          console.error('initRailingType  newScene=', newScene, 'newFullSelString=', newFullSelString);
        CHAM.visualizer.initRailingType(newScene.specifier, newFullSelString);
      },
      applyBySurfaceNames: function (selector, surfaceNames, surfaceLookup) {
        if (surfaceNames.length === 1) {
          var surface = surfaceLookup[surfaceNames[0]];
          CHAM.visualizer.apply(surface.specifiersurfacenumber, selector);

          return;
        }

        CHAM.visualizer.applyByFilter(function (surface, newSelector, currentSelector) {
          return surfaceNames.indexOf(surface.name) > -1 ?
            selector :
            currentSelector;
        });
      },
      goToRailingStep: function (stepData, stepNumber) {
        var steps = stepData.steps;

        if (stepNumber === 2) {
          var specifier = CHAM.visualizer.getCurrentSpecifier();
          var currentScene = _.find(CHAM.DATA.scenes, function (scene) {
            return scene.specifier === specifier;
          });
          var railingType = currentScene.color;
          if (railingType.indexOf('Brio Rhythm') > -1) { //round or square
            railingType = 'Brio Rhythm'; //Brio Rhythm Square, or Brio Rhythm Round
          }

          stepData.additionalOptions.removeClass('active').filter('[data-railing-type="' + railingType + '"]').addClass('active');
        }

        for (var index = 0; index < steps.length; index++) {
          var step = steps[index];
          step.nodes.toggleClass('active', step.number === stepNumber);
        }
      },
      createModel: function (productData) {
        //console.log(productData);
        var railing = _(productData.railing.products)
          .sortBy(function (product) {
            return product.sortOrder;
          })
          .groupBy(function (product) {
            return product.name;
          })
          .map(function (products, railingType) {
            return {
              railingType: railingType,
              info: CHAM.DATA.railingTypes[railingType],
              products: products
            };
          })
          .sortBy(function (model) {
            return model.info != null ? model.info.sortOrder : 99;
          })
          .value();

        var additionalOptions = _(productData)
          .filter(function (data, key) {
            return key !== 'railing';
          })
          .flatMap(function (data) {
            var surfaceNamesJoined = data.surfaceNames.join(',');

            return _(data.products)
              .sortBy(function (product) {
                return product.sortOrder;
              })
              .groupBy(function (product) {
                return product.name;
              })
              .map(function (products, railingType) {
                var productGroups = _(products)
                  .groupBy(function (product) {
                    return product.description;
                  })
                  .map(function (products, headerName) {
                    return {
                      surfaceNames: surfaceNamesJoined,
                      headerName: headerName,
                      products: products
                    };
                  })
                  .value();

                return {
                  railingType: railingType,
                  productGroups: productGroups
                };
              })
              .value();
          })
          .groupBy(function (model) {
            return model.railingType;
          })
          .mapValues(function (models) {
            return _.flatMap(models, function (model) {
              return model.productGroups;
            });
          })
          .value();

        return {
          railingModels: railing,
          railingSurfaceNames: productData.railing.surfaceNames.join(','),
          additionalOptions: additionalOptions
        };
      },
      render: CHAM.templates.railingProducts
    },
    'Lighting': {
      events: function (root, surfaces, productLookup) {
        var surfaceLookup = _.keyBy(surfaces, function (surface) {
          return surface.name;
        });

        root.on('click', '.product', function () {
          var self = $(this);
          var data = self.data();
          var product = productLookup[data.sel];
          var surface = surfaceLookup[data.surfaceName];

          self.addClass('active').siblings().removeClass('active');
          self.parents('.lighting-options').find('.light-type-holder').get(0).innerHTML = CHAM.templates.lightingCurrentProduct(product);

          CHAM.menu.closeSurfaceGroup([surface.specifiersurfacenumber]);
          CHAM.visualizer.apply(surface.specifiersurfacenumber, data.sel);
        });

        root.on('click', '.zoomin', function (e) {
          e.stopPropagation(); // Prevent product from applying.
          var sel = $(this).data('sel');
          var product = productLookup[sel];
          var title = product.description + ' - ' + product.color;

          wideZoom.render(product, title);
        });
      },
      createModel: function (productData) {
        var models = _.flatMap(productData, function (data) {
          var surfaceName = data.surfaceNames[0];

          return _(data.products)
            .sortBy(function (product) {
              return product.sortOrder;
            })
            .groupBy(function (product) {
              return product.description;
            })
            .map(function (products, headerName) {
              return {
                surfaceName: surfaceName,
                headerName: headerName,
                products: products
              };
            })
            .value();
        });

        return {
          models: models
        };
      },
      render: CHAM.templates.lightingProducts
    },
    'Wall': {
      events: function (root, surfaces, productLookup) {
        var isCladdingVersion = getQueryParameter('version') === 'cladding';
        if (isCladdingVersion == false) {
          root.find('#blend').addClass('hide');
        }
        var self = this;
        var onlySurface = surfaces[0];
        var blendProducts = false;
        var selector = parseSelTree(CHAM.VIZ.ChamViz({
          action: 'getFullSelString'
        }));
        var surfaceAppliedProduct = selector[parseInt(onlySurface.specifiersurfacenumber) - 1];

        if (surfaceAppliedProduct.children != null) {
          var firstProduct = productLookup[parseInt(surfaceAppliedProduct.children[0].sel)];
          var secondProduct = productLookup[parseInt(surfaceAppliedProduct.children[1].sel)];
        } else {
          var firstProduct = productLookup[parseInt(surfaceAppliedProduct.sel)];
        }

        var appliedProducts = {
          '60': firstProduct,
          '40': secondProduct,
          selectedSixty: false,
          selectedForty: false
        }
        this.renderBlendMenu(root, appliedProducts);

        if (appliedProducts['60'] != null) {
          root.find('.product').filter('[data-sel="' + appliedProducts['60'].id + '"]').addClass('active');
          appliedProducts.selectedSixty = true;
          appliedProducts.selectedForty = false;
        }

        if (appliedProducts['40'] != null) {
          root.find('.product').filter('[data-sel="' + appliedProducts['40'].id + '"]').addClass('active');
          appliedProducts.selectedSixty = false;
          appliedProducts.selectedForty = true;
        }

        root.on('click', '.tab-link', function () {
          var self = $(this);
          var tabNumber = self.data('tab');

          self.addClass('active').siblings().removeClass('active');
          root.find('.tab').removeClass('active').filter('[data-tab="' + tabNumber + '"]').addClass('active');
          if (tabNumber == 2) {
            root.find('#blend').addClass('hide');
          } else if (tabNumber == 1) {
            root.find('#blend').removeClass('hide');
          }
          if (isCladdingVersion == false) {
            console.log("22cladding");
            root.find('#blend').addClass('hide');
          }
        });

        root.on('click', '.product', function () {
          var sel = $(this).data('sel');
          var product = productLookup[sel];
          if (appliedProducts.selectedSixty == true && product.categoryName === 'Plank') {
            appliedProducts['60'] = product;
          }
          if (appliedProducts.selectedForty == true && product.categoryName === 'Plank') {
            appliedProducts['40'] = product;
          }
          if (appliedProducts['60'] != null && appliedProducts['40'] != null) {
            //Apply two products
            //CHAM.menu.closeSurfaceGroup([onlySurface.specifiersurfacenumber]);
            CHAM.visualizer.apply(onlySurface.specifiersurfacenumber, "1346384(" + appliedProducts['60'].id + "," + appliedProducts['40'].id + ")");
            appliedProducts.selectedForty = true;
            appliedProducts.selectedSixty = false;
          }

          if (blendProducts == true) {
            root.find('.product').removeClass('active');
          }
          if (blendProducts == false) {
            root.find('.product').removeClass('active').filter('[data-sel="' + sel + '"]').addClass('active');
            CHAM.menu.closeSurfaceGroup([onlySurface.specifiersurfacenumber]);
            CHAM.visualizer.apply(onlySurface.specifiersurfacenumber, sel);
            if (product.categoryName === 'Plank') {
              appliedProducts['60'] = product;
            }

          }
          self.renderBlendMenu(root, appliedProducts);

        });

        root.on('click', '.zoomin', function (e) {
          e.stopPropagation(); // Prevent product from applying.
          var sel = $(this).data('sel');
          var product = productLookup[sel];
          var title = 'Cladding - ' + product.color;
          defaultZoom.render(product, title);
        });

        root.on('click', '#blend', function () {
          root.find('#wall-btns').addClass('hide');
          root.find('.wall-type-info').addClass('hide');
          root.find('.blend-clad').addClass('active');

          blendProducts = true;
          if (appliedProducts['60'] != null && appliedProducts['40'] == null) {
            appliedProducts.selectedForty = true;
            appliedProducts.selectedSixty = false;
          }
          if (appliedProducts['60'] == null) {
            appliedProducts.selectedForty = false;
            appliedProducts.selectedSixty = true;
          }
          if (appliedProducts['60'] != null && appliedProducts['40'] != null) {
            CHAM.visualizer.apply(onlySurface.specifiersurfacenumber, "1346384(" + appliedProducts['60'].id + "," + appliedProducts['40'].id + ")");
          }
          self.renderBlendMenu(root, appliedProducts);
        });

        root.on('click', '.remove', function () {
          root.find('#wall-btns').removeClass('hide');
          root.find('.wall-type-info').removeClass('hide');
          root.find('.blend-clad').removeClass('active');
          if (appliedProducts['60'] != null) {
            root.find('.product').removeClass('active').filter('[data-sel="' + appliedProducts['60'].id + '"]').addClass('active');
            CHAM.visualizer.apply(onlySurface.specifiersurfacenumber, appliedProducts['60'].id);
          }
          blendProducts = false;
        });

        root.on('click', '.switch', function () {
          var temp = appliedProducts['60'];
          appliedProducts['60'] = appliedProducts['40'];
          appliedProducts['40'] = temp;
          CHAM.visualizer.apply(onlySurface.specifiersurfacenumber, "1346384(" + appliedProducts['60'].id + "," + appliedProducts['40'].id + ")");
          self.renderBlendMenu(root, appliedProducts);
        });

        root.on('click', '.forty', function () {
          appliedProducts.selectedForty = true;
          appliedProducts.selectedSixty = false;
          self.renderBlendMenu(root, appliedProducts);
        });

        root.on('click', '.sixty', function () {
          appliedProducts.selectedForty = false;
          appliedProducts.selectedSixty = true;
          self.renderBlendMenu(root, appliedProducts);
        });
      },
      createModel: function (productData) {
        return {
          claddingProducts: _.sortBy(productData.fiberonWall.products, function (product) {
            return product.color;
          }),
          genericProducts: _(productData.siding.products)
            .concat(productData.brick.products)
            .sortBy([function (product) {
              return product.categoryID;
            }, function (product) {
              return parseInt(product.warranty);
            }])
            .value()
        };
      },
      render: CHAM.templates.wallProducts,
      renderBlendMenu: function (root, appliedProducts) {
        if (appliedProducts.selectedSixty == true && appliedProducts['60'] != null) {
          root.find('.product').removeClass('active').filter('[data-sel="' + appliedProducts['60'].id + '"]').addClass('active');
        } else if (appliedProducts.selectedForty == true && appliedProducts['40'] != null) {
          root.find('.product').removeClass('active').filter('[data-sel="' + appliedProducts['40'].id + '"]').addClass('active');
        } else if (appliedProducts.selectedForty == true && appliedProducts['40'] == null) {
          root.find('.product').removeClass('active');
        }
        var html = CHAM.templates.blendMenu(appliedProducts);
        root.find('.blend-clad').html(html);

        //console.log("applied products", appliedProducts);
      }
    },
    'House Trim': {
      events: function (root, surfaces) {
        var onlySurface = surfaces[0];

        root.on('click', '.product', function () { // TODO refactor this to remove duplication.
          var sel = $(this).data('sel');

          CHAM.menu.closeSurfaceGroup([onlySurface.specifiersurfacenumber]);
          CHAM.visualizer.apply(onlySurface.specifiersurfacenumber, sel);
          root.find('.product').removeClass('active').filter('[data-sel="' + sel + '"]').addClass('active');
        });
      },
      createModel: function (productData) {
        var sorted = _.sortBy(productData.trim.products, function (product) {
          return parseInt(product.warranty);
        });
        return {
          trimProducts: sorted
        };
      },
      render: CHAM.templates.houseTrimProducts
    }
  };

  var noop = function () {};
  var emptyMenuCreator = {
    events: noop,
    createModel: noop,
    render: function () {
      return '<div class="no-products">No products available.</div>';
    }
  };

  var $container = $('#product-menus');

  CHAM.products = {
    //Gets products for each project
    data: function () {
      var seriesIds = _.map(CHAM.DATA.series, function (series) {
        return series.id;
      });
      CHAM.DATA.productsPromise = CHAM.services.getProductsBySeriesIDs(seriesIds);
    },
    render: function (surfacesGroups) {
      CHAM.DATA.productsPromise.then(function (response) {
        var menuItems = _.map(surfacesGroups, function (surfaceGroup) {
          var surfaceProducts = _(CHAM.DATA.series)
            .filter(function (series) {
              return _.some(surfaceGroup.surfaces,
                function (surface) {
                  return series.surfaceNames.indexOf(surface.name) > -1;
                });
            })
            .keyBy(function (series) {
              return series.key;
            })
            .mapValues(function (series) {
              return {
                surfaceNames: series.surfaceNames,
                products: response.series[series.id] || []
              };
            })
            .value();
          var menuCreator = productMenus[surfaceGroup.surfaceType] || emptyMenuCreator;
          var model = menuCreator.createModel(surfaceProducts);
          //console.log('model', model)
          //menuHTML creates menu for each category
          var menuHtml = CHAM.templates.productMenuWrapper(surfaceGroup.displayName, surfaceGroup.surfaceNumbers, menuCreator.render(model));
          var menuRoot = $(menuHtml);

          menuCreator.events(menuRoot, surfaceGroup.surfaces, response.lookup);

          return menuRoot;
        });

        $container.empty().append(menuItems);
      });
    },
    events: function () {
      //close products menu
      $container.on('click', '.product-menu .close', function () {
        var surfaceNumbers = $(this).data('surfaces').toString().split(',');
        CHAM.menu.closeSurfaceGroup(surfaceNumbers);
      });

      defaultZoom.events();
      wideZoom.events();
    }
  }
})(jQuery);