var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  CHAM.upload = {
    init: function () {
      this.projects = $('#my-projects');
      this.mapper = $('#uploadOverlay, #upload-container');
    },
    showImageMapper: function () {
      this.mapper.fadeIn('slow');
    },
    events: function () {
      var self = this;
      var projects = this.projects;

      $('#upload, #upload-banner').on('click', function () {
        if (!projects.MyProjects({ action: 'checkCookieAuth' }))
          projects.MyProjects({
            action: 'open',
            redirect: function () {
              self.showImageMapper();
              projects.MyProjects({ action: 'close' });
            }
          });
        else
          self.showImageMapper();
      });
    }
  };
})(jQuery);
