﻿var Observable = (function () {
  var directInvoke = function (func, value) { func(value); };
  var spreadInvoke = function (func, args) { func.apply(null, args); };

  var Observable = function () {
    /// <summary>An implementation of the observer pattern. Allows for push based notification to any number of subscribers.</summary>

    if (!(this instanceof Observable))
      return new Observable();

    this.observers = null;
  }

  Observable.prototype.subscribe = function (listener) {
    /// <signature>
    /// <summary>Subscribe to notifications from the observable.</summary>
    /// <param name="listener" type="(params: any) => void">A listener function.</param>
    /// <returns type="()/Function">A function to unsubscribe.</returns>
    /// </signature>

    if (this.observers != null)
      this.observers.push(listener);
    else
      this.observers = [listener];

    var self = this;

    return function () {
      var index = self.observers.indexOf(listener);
      if (index === -1)
        return;

      self.observers.splice(index, 1);
    };
  }

  Observable.prototype.emit = function (value) {
    if (this.observers == null)
      return;

    var isSpread = arguments.length > 1;
    var invoke = isSpread ? spreadInvoke : directInvoke;
    var parameter = isSpread
      ? Array.prototype.slice.call(arguments)
      : value;

    for (var index = 0; index < this.observers.length; index++)
      invoke(this.observers[index], parameter);
  }

  Observable.prototype.bindEmit = function () {
    var args = Array.prototype.slice.call(arguments);
    args.unshift(this);

    return this.emit.bind.apply(this.emit, args);
  }

  Observable.merge = function (observables) {
    var wrapper = new Observable();
    var boundEmit = wrapper.emit.bind(wrapper);

    for (var index = 0; index < arguments.length; index++)
      arguments[index].subscribe(boundEmit);

    return {
      subscribe: wrapper.subscribe.bind(wrapper)
    };
  }

  Observable.fromDomEvent = function (element, events, selector) {
    /// <signature>
    /// <summary>Creates an observable stream from a DOM event.</summary>
    /// <param name="element" type="HtmlElement">The DOM element.</param>
    /// <param name="events" type="string">One or more space-separated event types and optional namespaces, such as "click" or "keydown.myPlugin".</param>
    /// <param name="selector" type="string?">A selector string to filter the descendants of the selected elements that trigger the event. If the selector is null or omitted, the event is always triggered when it reaches the selected element.</param>
    /// </signature>
    /// <signature>
    /// <summary>Creates an observable stream from a jQuery object.</summary>
    /// <param name="node" type="JQuery">The jQuery object.</param>
    /// <param name="events" type="string">One or more space-separated event types and optional namespaces, such as "click" or "keydown.myPlugin".</param>
    /// <param name="selector" type="string?">A selector string to filter the descendants of the selected elements that trigger the event. If the selector is null or omitted, the event is always triggered when it reaches the selected element.</param>
    /// </signature>

    var node = element instanceof jQuery ? element : $(element);
    var observable = new Observable();

    node.on(events, selector || null, function (event) {
      observable.emit(this, event);
    });

    return observable;
  }

  return Observable;
})();
