/* ==========================================================================
   Social Media Plugin
   Author: Matthew Meyer
   Add to the head of the page:
    <script src="ChamWebTools/SocialMedia/SocialMedia.js"></script>

   Initializing:
        var config = {
            fbAppID: '', // FB App ID from Wyatt
		    imperURL: '',  // The impersonation URL for Example: https://gaf.chameleonpower.com
		    title: 'Check out this re-design I did of my house.',  // The Title you want to post
            pttitle: 'Check out this re-design I did of my house.', // The Title you want to post to pintrest
		    sel: '', // Get full sel string. Do not have to have this it will generate it automatically from The Test field of ChamViz
		    spec: '', // The specifier. Do not have to have this it will generate it automatically from The Test field of ChamViz
		    siteName: '', // The Site's Name. Do not have to have this it will generate it automatically from The Test field of ChamViz
            viz: null, // The ChamViz Test Action if you want to pass it the values.  Do not have to specify this only in rare conditions.
            vizSelector: '#visualizer', // the selector name to the ChamViz section.  Do not have to specifier only if you changed the location in which ChamViz initializes to.
			hasGPlus: true, // Do we enable Google Plus?  Defaults to False so this doesn't effect any other websites.
			isFacebookLikeLink: true, // Is this a Facebook Like Link? Defaults to False so this doesn't effect any other websites.
		    FBLinkSelector: '#facebookLink', // the Selector name to the facebook Link.  Usually it's #facebookLink
		    TWLinkSelector: '#twitterLink', // the Selector name to the twitter Link.  Usually it's #twitterLink
		    PTLinkSelector: '#pinterestLink', // the Selector name to the pinterest Link.  Usually it's #pinterestLink
			GPlusLinkSelector: '#googleplusLink', // The Selector name to the Google Plus Link. Usually it's #googleplusLink
		    SocialBTNLoc: '#vizControls',  // the selector name to the location in which to generate the social buttons
		    target: '_blank' // how to open the links, _self, _blank, _new, etc.
        };
        socialMedia.init(config);

   Updating:
    only have to pass the Specifier and full sel string
    socialMedia.update({ specifier: '', sel: '' });

   ========================================================================== */

var socialMedia = (function (socialMedia, undefined) {

	var defaults = {
		viz: null,
		container: null,
		socialcontainer: null,
		scriptContainer: null,
		fbRootContainer: null,
		fbIframeContainer: null,
		metaURL: null,
		metaFBAppID: null,
		metaImage: null,
		metaTitle: null,
	    metaPTTitle: null,
		metaSiteName: null,
		metaLocale: null,
		metaType: null,
		metaName: null,
		metaDescription: null,
		metaGPlusDescription: null,
		metaGPlusName: null,
		metaGPlusImage: null,
		fbLink: null,
		twLink: null,
		ptLink: null,
		gplusLink: null,
		FBUrl: '',
		QueryString: '',
		returnURL: '',
		FBFeedURL: '',
		TWFeedURL: '',
		PTFeedURL: '',
		GPFeedUrl: '',
		metas: [],
		renderserver: '',
		fbImageSize: 200,
		GooglePlusDom: null,
		metaProperties: {
		    title: 'Check out this re-design I did of my house.',
		    PTTitle: 'Check out this re-design I did of my house.',
			type: 'website',
			url: '',
			image: '',
			site_name: '',
			locale: 'en_US',
			fbAppID: '',
			name: '',
			description: '',
			gPlusName: '',
			gPlusDescription: '',
			gPlusImage: ''
		},
		config: {
			fbAppID: '',
			imperURL: '',
			imageURL: '',
			title: '',
            pttitle:'',
			sel: '',
			spec: '',
			siteName: '',
			viz: null,
			hasGPlus: false,
			isFacebookLikeLink: false,
			vizSelector: '#visualizer',
			FBLinkSelector: '#facebookLink',
			TWLinkSelector: '#twitterLink',
			PTLinkSelector: '#pinterestLink',
			GPlusLinkSelector: '#googleplusLink',
			SocialBTNLoc: '#socialLinks',
			target: '_blank'
		}
	};

  function init(config) {
    //console.log('config', config);

		if (config) $.extend(defaults.config, config);
		defaults.viz = (defaults.config.viz) ? defaults.config.viz : $(defaults.config.vizSelector).ChamViz({ action: 'test' });

		defaults.config.siteName = (defaults.config.siteName) ? defaults.config.siteName : defaults.viz.siteid;
		var _fullsel = $(defaults.config.vizSelector).ChamViz({ action: 'getFullSelString' }) || '';
		defaults.config.sel = (defaults.config.sel) ? defaults.config.sel.replace(/ /g, '') : _fullsel.replace(/ /g, '');
		defaults.config.spec = (defaults.config.spec) ? defaults.config.spec : defaults.viz.spec;

		defaults.renderserver = defaults.viz.renderserver;
		if (defaults.config.imperURL) {
		    if (defaults.config.imperURL.indexOf('/') > -1) defaults.metaProperties.url = defaults.config.imperURL;
		    else defaults.metaProperties.url = defaults.config.imperURL + window.location.pathname;
		}
		else defaults.metaProperties.url = window.location.protocol + "//" + window.location.host + window.location.pathname;


		defaults.metaProperties.fbAppID = defaults.config.fbAppID;
		defaults.metaProperties.image = defaults.renderserver + 'GetImage.ashx?Specifier=' + encodeURIComponent(defaults.config.spec) + '&Type=BaseImage&Size=' + defaults.fbImageSize + '&Sel=' + defaults.config.sel + '&SiteID=' + defaults.viz.siteid;

		defaults.metaProperties.title = defaults.config.title;
		defaults.metaProperties.PTTitle = defaults.config.pttitle || defaults.config.title;
		

		defaults.metaProperties.site_name = defaults.config.siteName;

		defaults.FBUrl = defaults.metaProperties.url;

		defaults.QueryString = '?fblink=' + (defaults.config.spec || '').replace(/\\/g, '.') + 'seln' + defaults.config.sel;

		defaults.returnURL = defaults.metaProperties.url + defaults.QueryString;
		defaults.returnURL = defaults.returnURL.replace('#', '');
		if (!defaults.config.isFacebookLikeLink) {
		    var fbreturnURL = (defaults.returnURL.indexOf('://') > -1) ? '' : window.location.protocol + '//';
		    fbreturnURL += encodeURIComponent(defaults.returnURL);
		    defaults.FBFeedURL = "//www.facebook.com/dialog/feed?app_id=" + defaults.metaProperties.fbAppID + "&link=" + encodeURIComponent(defaults.returnURL) + "&picture=" + encodeURIComponent(defaults.metaProperties.image) + "&name=" + defaults.metaProperties.site_name + "&description=" + defaults.metaProperties.title + "&redirect_uri=" + fbreturnURL;
		}
		else {
			defaults.FBFeedURL = '//www.facebook.com/plugins/like.php?href=' + encodeURIComponent(defaults.returnURL) + '&width&layout=standard&action=like&show_faces=true&share=true&height=35&appId=' + defaults.metaProperties.fbAppID;
			//defaults.FBFeedURL = "//www.facebook.com/dialog/feed?app_id=" + defaults.metaProperties.fbAppID + "&link=" + encodeURIComponent(defaults.returnURL) + "&picture=" + encodeURIComponent(defaults.metaProperties.image) + "&name=" + defaults.metaProperties.site_name + "&description=" + defaults.metaProperties.title + "&redirect_uri=" + encodeURIComponent(defaults.returnURL);
		}
		defaults.TWFeedURL = '//twitter.com/share?url=' + encodeURIComponent(defaults.returnURL + '&x=1') + '&text=' + defaults.metaProperties.title;
		defaults.PTFeedURL = '//pinterest.com/pin/create/button/?url=' + encodeURIComponent(defaults.returnURL) + '&media=' + encodeURIComponent(defaults.metaProperties.image) + '&description=' + encodeURIComponent(defaults.metaProperties.PTTitle);
		defaults.container = $('head');
		defaults.socialcontainer = $(defaults.config.SocialBTNLoc);

		$(defaults.metaImage).remove();
		$(defaults.metaFBAppID).remove();
		$(defaults.metaLocale).remove();
		$(defaults.metaSiteName).remove();
		$(defaults.metaTitle).remove();
		$(defaults.metaType).remove();
		$(defaults.metaURL).remove();

		if (defaults.scriptContainer == null) {
			defaults.scriptContainer = $('<script/>', {
				type: "text/javascript",
				src: "//connect.facebook.net/" + defaults.metaProperties.locale + "/all.js?appId=" + defaults.config.fbAppID + "&xfbml=1"
			}).appendTo(defaults.container);
		}
		if ($(defaults.fbRootContainer).length > 0) {
			defaults.fbRootContainer.remove();
			defaults.fbRootContainer = $('<div/>', {
				id: 'fb-root'
			}).appendTo('body');
		}
		else {
			defaults.fbRootContainer = $('<div/>', {
				id: 'fb-root'
			}).appendTo('body');
		}

		// setup the meta title
		defaults.metaTitle = $('<meta/>', {
			id: "facebookTitle",
			property: 'og:title',
			content: defaults.metaProperties.title
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaTitle);

		// setup the meta type
		defaults.metaType = $('<meta/>', {
			id: "contentType",
			property: 'og:type',
			content: defaults.metaProperties.type
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaType);

		// setup the meta url
		defaults.metaURL = $('<meta/>', {
			id: "facebookMetaUrl",
			property: 'og:url',
			content: defaults.metaProperties.url
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaURL);


		// setup the meta image
		defaults.metaImage = $('<meta/>', {
			id: "facebookMetaImage",
			property: 'og:image',
			content: defaults.metaProperties.image
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaImage);

		// setup the meta site name
		defaults.metaSiteName = $('<meta/>', {
			id: "Site_Name",
			property: 'og:site_name',
			content: defaults.metaProperties.site_name
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaSiteName);

		// setup the meta locale
		defaults.metaLocale = $('<meta/>', {
			id: "siteLocale",
			property: 'og:locale',
			content: defaults.metaProperties.locale
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaLocale);

		// setup the meta app_id
		defaults.metaFBAppID = $('<meta/>', {
			id: "fbAppID",
			property: 'og:app_id',
			content: defaults.metaProperties.fbAppID
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaFBAppID);

		// initialize social links
		defaults.fbLink = $(defaults.config.FBLinkSelector);
		defaults.twLink = $(defaults.config.TWLinkSelector);
		defaults.ptLink = $(defaults.config.PTLinkSelector);



		// setup href for Facebook
		if (!defaults.config.isFacebookLikeLink) {
			defaults.fbLink.attr('href', defaults.FBFeedURL);
			defaults.fbLink.attr('target', defaults.config.target);
			defaults.fbLink.addClass('ChamStats');
			defaults.fbLink.attr('data-csaction', 'share');
			defaults.fbLink.attr('data-csdata', 'Facebook');
		}
		else {
			defaults.fbLink.empty();
			defaults.fbIframeContainer = $('<iframe/>', {
				'id': 'facebookIframe',
				'src': defaults.FBFeedURL,
				'scrolling': 'no',
				'frameborder': 0,
				'allowTransparency': true
			}).css({
				'overflow': 'hidden',
				'width': 400,
				'height': 25
			}).appendTo(defaults.fbLink);
		}

	  // setup href for twitter
		defaults.twLink.attr('href', defaults.TWFeedURL);
		defaults.twLink.attr('target', defaults.config.target);
		defaults.twLink.addClass('ChamStats');
		defaults.twLink.attr('data-csaction', 'share');
		defaults.twLink.attr('data-csdata', 'Twitter');

		// setup href for Pinterest
		defaults.ptLink.attr('href', defaults.PTFeedURL);
		defaults.ptLink.attr('target', defaults.config.target);
		defaults.ptLink.attr('target', defaults.config.target);
		defaults.ptLink.addClass('ChamStats');
		defaults.ptLink.attr('data-csaction', 'share');
		defaults.ptLink.attr('data-csdata', 'Pinterest');


		/* If google plus is enabled */
		if (defaults.config.hasGPlus) {
			window.___gcfg = {
				lang: 'us-US',
				parsetags: 'onload'
			};
			(function () {
				var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
				po.src = 'https://apis.google.com/js/platform.js';
				var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
			})();
			//$('head').append('<script src="https://apis.google.com/js/client:platform.js" async defer></script>');

			defaults.GPFeedUrl = 'https://plus.google.com/share?url=';

			defaults.gplusLink = $(defaults.config.GPlusLinkSelector);

			defaults.gplusLink.attr({
				'href': defaults.GPFeedUrl + defaults.config.imperURL + defaults.QueryString,
				'data-href': defaults.config.imperURL
				, 'data-align': 'left'
				, 'data-width': 50
				, 'data-height': 50
				, 'data-expandTo': 'top'
			});
			defaults.gplusLink.addClass('ChamStats');
			defaults.gplusLink.attr('data-csaction', 'share');
			defaults.gplusLink.attr('data-csdata', 'GooglePlus');

			var buildGoogleBody = [];
			defaults.GooglePlusDom = document.createElement('body');

			buildGoogleBody.push('<span itemprop="name">' + defaults.metaProperties.title + '</span>');
			buildGoogleBody.push('<span itemprop="description">' + defaults.metaProperties.title + '<br />' + defaults.returnURL + '</span>');
			buildGoogleBody.push('<img itemprop="image" src="' + defaults.metaProperties.image + '">');

			defaults.metaGPlusName = $('<meta/>', {
				'class': 'googlePlusName',
				'itemprop': 'name',
				'content': defaults.metaProperties.title
			}).appendTo('head');

			defaults.metas.push(defaults.metaGPlusName);
			defaults.metaGPlusDescription = $('<meta/>', {
				'class': 'googlePlusDescription',
				'itemprop': 'description',
				'content': defaults.metaProperties.title + '<br/>' + defaults.returnURL
			}).appendTo('head');

			defaults.metas.push(defaults.metaGPlusDescription);
			defaults.metaGPlusImage = $('<meta/>', {
				'class': 'googlePlusImage',
				'itemprop': 'image',
				'content': defaults.metaProperties.image
			}).appendTo('head');
			defaults.metas.push(defaults.metaGPlusImage);

			$('<link/>', {
				rel: 'canonical',
				href: defaults.metaProperties.image
			}).appendTo('head');

			var div = document.createElement('div');
			div.innerHTML = buildGoogleBody.join('');
			defaults.GooglePlusDom.appendChild(div);

		}
		if (window.ChamStats == void 0) {
		    var scrpt = document.createElement('script');
		    scrpt.src = '//ca.chameleonpower.com/stats.js';
		    document.head.appendChild(scrpt);
		}
	};

  function update(config) {

		// change content
		if (config) $.extend(defaults.config, config);

		defaults.config.siteName = (defaults.config.siteName) ? defaults.config.siteName : defaults.viz.siteid;
		defaults.config.sel = $(defaults.config.vizSelector).ChamViz({ action: 'getFullSelString' }).replace(/ /g, '');
		defaults.config.spec = (defaults.config.spec) ? defaults.config.spec : defaults.viz.spec;

		defaults.renderserver = defaults.viz.renderserver;

		if (defaults.config.imperURL) {
		    if (defaults.config.imperURL.indexOf('/') > -1) defaults.metaProperties.url = defaults.config.imperURL;
		    else defaults.metaProperties.url = defaults.config.imperURL + window.location.pathname;
		}
		else defaults.metaProperties.url = window.location.protocol + "//" + window.location.host + window.location.pathname;

		defaults.metaProperties.fbAppID = defaults.config.fbAppID;
		defaults.metaProperties.image = defaults.renderserver + 'GetImage.ashx?Specifier=' + defaults.config.spec + '&Type=BaseImage&Size=' + defaults.fbImageSize + '&Sel=' + defaults.config.sel + '&SiteID=' + defaults.viz.siteid;
		defaults.metaProperties.title = defaults.config.title;
		defaults.metaProperties.PTTitle = defaults.config.pttitle || defaults.config.title;
		defaults.metaProperties.site_name = defaults.config.siteName;

		defaults.FBUrl = defaults.metaProperties.url;


		defaults.QueryString = '?fblink=' + defaults.config.spec.replace(/\\/g, '.') + 'seln' + defaults.config.sel;
		defaults.returnURL = defaults.metaProperties.url + defaults.QueryString;
		defaults.returnURL = defaults.returnURL.replace('#', '');
		if (!defaults.config.isFacebookLikeLink) {
		    var fbreturnURL = (defaults.returnURL.indexOf('://') > -1) ? '' : window.location.protocol + '//';
		    fbreturnURL += encodeURIComponent(defaults.returnURL);
		    defaults.FBFeedURL = "//www.facebook.com/dialog/feed?app_id=" + defaults.metaProperties.fbAppID + "&link=" + encodeURIComponent(defaults.returnURL) + "&picture=" + encodeURIComponent(defaults.metaProperties.image) + "&name=" + defaults.metaProperties.site_name + "&description=" + defaults.metaProperties.title + "&redirect_uri=" + fbreturnURL;
		}
		else {
			defaults.FBFeedURL = '//www.facebook.com/plugins/like.php?href=' + encodeURIComponent(defaults.returnURL) + '&width&layout=standard&action=like&show_faces=true&share=true&height=35&appId=' + defaults.metaProperties.fbAppID;
			defaults.fbIframeContainer.attr('src', defaults.FBFeedURL);
		}
		defaults.TWFeedURL = '//twitter.com/share?url=' + encodeURIComponent(defaults.returnURL + '&x=1') + '&text=' + defaults.metaProperties.title;
		defaults.PTFeedURL = '//pinterest.com/pin/create/button/?url=' + encodeURIComponent(defaults.returnURL) + '&media=' + encodeURIComponent(defaults.metaProperties.image) + '&description=' + defaults.metaProperties.PTTitle;

		// update existing meta tags
		$(defaults.metaTitle).attr('content', defaults.metaProperties.title);
		$(defaults.metaFBAppID).attr('content', defaults.metaProperties.fbAppID);
		$(defaults.metaImage).attr('content', defaults.metaProperties.image);
		$(defaults.metaSiteName).attr('content', defaults.metaProperties.site_name);
		$(defaults.metaURL).attr('content', defaults.metaProperties.url);

		// update href for Facebook
		if (!defaults.config.isFacebookLikeLink) {
			defaults.fbLink.attr('href', defaults.FBFeedURL);
			defaults.fbLink.attr('target', defaults.config.target);
		}
		// update href for twitter
		defaults.twLink.attr('href', defaults.TWFeedURL);
		defaults.twLink.attr('target', defaults.config.target);

		// update href for Pinterest
		defaults.ptLink.attr('href', defaults.PTFeedURL);
		defaults.ptLink.attr('target', defaults.config.target);

		/* If google plus is enabled */
		if (defaults.config.hasGPlus) {

			defaults.gplusLink.attr({
				'href': defaults.GPFeedUrl + defaults.config.imperURL + defaults.QueryString,
                'target': defaults.config.target
			});

			$(defaults.metaGPlusName).attr('content', defaults.metaProperties.title);
			$(defaults.metaGPlusDescription).attr('content', defaults.metaProperties.title);
			$(defaults.metaGPlusImage).attr('content', defaults.metaProperties.image);

		}
	};

	function NonVizInit(config) {
		if (config) $.extend(defaults.config, config);

		defaults.config.siteName = (defaults.config.siteName) ? defaults.config.siteName : '';

		if (defaults.config.imperURL) {
		    if (defaults.config.imperURL.indexOf('/') > -1) defaults.metaProperties.url = defaults.config.imperURL;
		    else defaults.metaProperties.url = defaults.config.imperURL + window.location.pathname;
		}
		else defaults.metaProperties.url = window.location.protocol + "//" + window.location.host + window.location.pathname;

		defaults.metaProperties.fbAppID = defaults.config.fbAppID;
		defaults.metaProperties.image = defaults.config.imageURL;
		defaults.metaProperties.title = defaults.config.title;
		defaults.metaProperties.PTTitle = defaults.config.pttitle || defaults.config.title;
		defaults.metaProperties.site_name = defaults.config.siteName;

		defaults.FBUrl = defaults.metaProperties.url;

		defaults.returnURL = defaults.metaProperties.url;
		defaults.returnURL = defaults.returnURL.replace(/#/ig, '');
		if (!defaults.config.isFacebookLikeLink) {
		    var fbreturnURL = (defaults.returnURL.indexOf('://') > -1) ? '' : window.location.protocol + '//';
		    fbreturnURL += encodeURIComponent(defaults.returnURL);
		    defaults.FBFeedURL = "//www.facebook.com/dialog/feed?app_id=" + defaults.metaProperties.fbAppID + "&link=" + encodeURIComponent(defaults.returnURL) + "&picture=" + encodeURIComponent(defaults.metaProperties.image) + "&name=" + defaults.metaProperties.site_name + "&description=" + defaults.metaProperties.title + "&redirect_uri=" + fbreturnURL;
		}
		else {
			defaults.FBFeedURL = '//www.facebook.com/plugins/like.php?href=' + encodeURIComponent(defaults.returnURL) + '&width&layout=standard&action=like&show_faces=true&share=true&height=35&appId=' + defaults.metaProperties.fbAppID;
			//defaults.FBFeedURL = "//www.facebook.com/dialog/feed?app_id=" + defaults.metaProperties.fbAppID + "&link=" + encodeURIComponent(defaults.returnURL) + "&picture=" + encodeURIComponent(defaults.metaProperties.image) + "&name=" + defaults.metaProperties.site_name + "&description=" + defaults.metaProperties.title + "&redirect_uri=" + encodeURIComponent(defaults.returnURL);

		}
		defaults.TWFeedURL = '//twitter.com/share?url=' + encodeURIComponent(defaults.returnURL) + '&text=' + defaults.metaProperties.title;
		defaults.PTFeedURL = '//pinterest.com/pin/create/button/?url=' + encodeURIComponent(defaults.returnURL) + '&media=' + encodeURIComponent(defaults.metaProperties.image) + '&description=' + defaults.metaProperties.PTTitle;
		defaults.container = $('head');
		defaults.socialcontainer = $(defaults.config.SocialBTNLoc);

		$(defaults.metaImage).remove();
		$(defaults.metaFBAppID).remove();
		$(defaults.metaLocale).remove();
		$(defaults.metaSiteName).remove();
		$(defaults.metaTitle).remove();
		$(defaults.metaType).remove();
		$(defaults.metaURL).remove();

		if (defaults.scriptContainer == null) {
			defaults.scriptContainer = $('<script/>', {
				type: "text/javascript",
				src: "//connect.facebook.net/" + defaults.metaProperties.locale + "/all.js?appId=" + defaults.config.fbAppID + "&xfbml=1"
			}).appendTo(defaults.container);
		}
		if ($(defaults.fbRootContainer).length > 0) {
			defaults.fbRootContainer.remove();
			defaults.fbRootContainer = $('<div/>', {
				id: 'fb-root'
			}).appendTo('body');
		}
		else {
			defaults.fbRootContainer = $('<div/>', {
				id: 'fb-root'
			}).appendTo('body');
		}

		// setup the meta title
		defaults.metaTitle = $('<meta/>', {
			id: "facebookTitle",
			property: 'og:title',
			content: defaults.metaProperties.title
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaTitle);

		// setup the meta type
		defaults.metaType = $('<meta/>', {
			id: "contentType",
			property: 'og:type',
			content: defaults.metaProperties.type
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaType);

		// setup the meta url
		defaults.metaURL = $('<meta/>', {
			id: "facebookMetaUrl",
			property: 'og:url',
			content: defaults.metaProperties.url
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaURL);

		// setup the meta image
		defaults.metaImage = $('<meta/>', {
			id: "facebookMetaImage",
			property: 'og:image',
			content: defaults.metaProperties.image
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaImage);

		// setup the meta site name
		defaults.metaSiteName = $('<meta/>', {
			id: "Site_Name",
			property: 'og:site_name',
			content: defaults.metaProperties.site_name
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaSiteName);

		// setup the meta locale
		defaults.metaLocale = $('<meta/>', {
			id: "siteLocale",
			property: 'og:locale',
			content: defaults.metaProperties.locale
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaLocale);

		// setup the meta app_id
		defaults.metaFBAppID = $('<meta/>', {
			id: "fbAppID",
			property: 'og:app_id',
			content: defaults.metaProperties.fbAppID
		}).appendTo(defaults.container);
		defaults.metas.push(defaults.metaFBAppID);

		// initialize social links
		defaults.fbLink = $(defaults.config.FBLinkSelector);
		defaults.twLink = $(defaults.config.TWLinkSelector);
		defaults.ptLink = $(defaults.config.PTLinkSelector);

		// setup href for Facebook
		if (!defaults.config.isFacebookLikeLink) {
			defaults.fbLink.attr('href', defaults.FBFeedURL);
			defaults.fbLink.attr('target', defaults.config.target);
			defaults.fbLink.addClass('ChamStats');
			defaults.fbLink.attr('data-csaction', 'share');
			defaults.fbLink.attr('data-csdata', 'Facebook');
		}
		else {
			defaults.fbLink.empty();
			defaults.fbIframeContainer = $('<iframe/>', {
				'id': 'facebookIframe',
				'src': defaults.FBFeedURL,
				'scrolling': 'no',
				'frameborder': 0,
				'allowTransparency': true
			}).css({
				'overflow': 'hidden',
				'width': 400,
				'height': 25
			}).appendTo(defaults.fbLink);
		}

		// setup href for twitter
		defaults.twLink.attr('href', defaults.TWFeedURL);
		defaults.twLink.attr('target', defaults.config.target);
		defaults.twLink.addClass('ChamStats');
		defaults.twLink.attr('data-csaction', 'share');
		defaults.twLink.attr('data-csdata', 'Twitter');

		// setup href for Pinterest
		defaults.ptLink.attr('href', defaults.PTFeedURL);
		defaults.ptLink.attr('target', defaults.config.target);
		defaults.ptLink.addClass('ChamStats');
		defaults.ptLink.attr('data-csaction', 'share');
		defaults.ptLink.attr('data-csdata', 'Pinterest');

		/* If google plus is enabled */
		if (defaults.config.hasGPlus) {
			window.___gcfg = {
				lang: 'us-US',
				parsetags: 'onload'
			};
			(function () {
				var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
				po.src = 'https://apis.google.com/js/platform.js';
				var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
			})();
			//$('head').append('<script src="https://apis.google.com/js/client:platform.js" async defer></script>');

			defaults.GPFeedUrl = 'https://plus.google.com/share?url=';

			defaults.gplusLink = $(defaults.config.GPlusLinkSelector);

			defaults.gplusLink.attr({
				'href': defaults.GPFeedUrl + defaults.config.imperURL + defaults.QueryString,
				'data-href': defaults.config.imperURL
				, 'data-align': 'left'
				, 'data-width': 50
				, 'data-height': 50
				, 'data-expandTo': 'top'
			});
			defaults.gplusLink.addClass('ChamStats');
			defaults.gplusLink.attr('data-csaction', 'share');
			defaults.gplusLink.attr('data-csdata', 'GooglePlus');

			var buildGoogleBody = [];
			defaults.GooglePlusDom = document.createElement('body');

			buildGoogleBody.push('<span itemprop="name">' + defaults.metaProperties.title + '</span>');
			buildGoogleBody.push('<span itemprop="description">' + defaults.metaProperties.title + '<br />' + defaults.returnURL + '</span>');
			buildGoogleBody.push('<img itemprop="image" src="' + defaults.metaProperties.image + '">');

			defaults.metaGPlusName = $('<meta/>', {
				'class': 'googlePlusName',
				'itemprop': 'name',
				'content': defaults.metaProperties.title
			}).appendTo('head');

			defaults.metas.push(defaults.metaGPlusName);
			defaults.metaGPlusDescription = $('<meta/>', {
				'class': 'googlePlusDescription',
				'itemprop': 'description',
				'content': defaults.metaProperties.title + '<br/>' + defaults.returnURL
			}).appendTo('head');

			defaults.metas.push(defaults.metaGPlusDescription);
			defaults.metaGPlusImage = $('<meta/>', {
				'class': 'googlePlusImage',
				'itemprop': 'image',
				'content': defaults.metaProperties.image
			}).appendTo('head');
			defaults.metas.push(defaults.metaGPlusImage);

			$('<link/>', {
				rel: 'canonical',
				href: defaults.metaProperties.image
			}).appendTo('head');

			var div = document.createElement('div');
			div.innerHTML = buildGoogleBody.join('');
			defaults.GooglePlusDom.appendChild(div);

		}
		if (window.ChamStats == void 0) {
		    var scrpt = document.createElement('script');
		    scrpt.src = '//ca.chameleonpower.com/stats.js';
		    document.head.appendChild(scrpt);
		}
	};

	function NonVizUpdate(config) {
		// change content
		if (config) $.extend(defaults.config, config);

		defaults.config.siteName = (defaults.config.siteName) ? defaults.config.siteName : '';

		if (defaults.config.imperURL) {
		    if (defaults.config.imperURL.indexOf('/') > -1) defaults.metaProperties.url = defaults.config.imperURL;
		    else defaults.metaProperties.url = defaults.config.imperURL + window.location.pathname;
		}
		else defaults.metaProperties.url = window.location.protocol + "//" + window.location.host + window.location.pathname;

		defaults.metaProperties.fbAppID = defaults.config.fbAppID;
		defaults.metaProperties.image = defaults.config.imageURL;
		defaults.metaProperties.title = defaults.config.title;
		defaults.metaProperties.PTTitle = defaults.config.pttitle || defaults.config.title;
		defaults.metaProperties.site_name = defaults.config.siteName;

		defaults.FBUrl = defaults.metaProperties.url;

		//defaults.QueryString = '?fblink=' + defaults.config.spec.replace(/\\/g, '.') + 'seln' + defaults.config.sel;
		defaults.returnURL = defaults.metaProperties.url;
		defaults.returnURL = defaults.returnURL.replace(/#/ig, '');
		if (!defaults.config.isFacebookLikeLink) {
		    var fbreturnURL = (defaults.returnURL.indexOf('://') > -1) ? '' : window.location.protocol + '//';
		    fbreturnURL += encodeURIComponent(defaults.returnURL);
		    defaults.FBFeedURL = "//www.facebook.com/dialog/feed?app_id=" + defaults.metaProperties.fbAppID + "&link=" + encodeURIComponent(defaults.returnURL) + "&picture=" + encodeURIComponent(defaults.metaProperties.image) + "&name=" + defaults.metaProperties.site_name + "&description=" + defaults.metaProperties.title + "&redirect_uri=" + fbreturnURL;
		}
		else {
			defaults.FBFeedURL = '//www.facebook.com/plugins/like.php?href=' + encodeURIComponent(defaults.returnURL) + '&width&layout=standard&action=like&show_faces=true&share=true&height=35&appId=' + defaults.metaProperties.fbAppID;
			defaults.fbIframeContainer.attr('src', defaults.FBFeedURL);
		}
		defaults.TWFeedURL = '//twitter.com/share?url=' + encodeURIComponent(defaults.returnURL) + '&text=' + defaults.metaProperties.title;
		defaults.PTFeedURL = '//pinterest.com/pin/create/button/?url=' + encodeURIComponent(defaults.returnURL) + '&media=' + encodeURIComponent(defaults.metaProperties.image) + '&description=' + defaults.metaProperties.PTTitle;

		// update existing meta tags
		$(defaults.metaTitle).attr('content', defaults.metaProperties.title);
		$(defaults.metaFBAppID).attr('content', defaults.metaProperties.fbAppID);
		$(defaults.metaImage).attr('content', defaults.metaProperties.image);
		$(defaults.metaSiteName).attr('content', defaults.metaProperties.site_name);
		$(defaults.metaURL).attr('content', defaults.metaProperties.url);

		// update href for Facebook
		if (!defaults.config.isFacebookLikeLink) {
			defaults.fbLink.attr('href', defaults.FBFeedURL);
			defaults.fbLink.attr('target', defaults.config.target);
		}
		// update href for twitter
		defaults.twLink.attr('href', defaults.TWFeedURL);
		defaults.twLink.attr('target', defaults.config.target);

		// update href for Pinterest
		defaults.ptLink.attr('href', defaults.PTFeedURL);
		defaults.ptLink.attr('target', defaults.config.target);

		/* If google plus is enabled */
		if (defaults.config.hasGPlus) {

			defaults.gplusLink.attr({
				'href': defaults.GPFeedUrl + defaults.config.imperURL + defaults.QueryString,
			});

			$(defaults.metaGPlusName).attr('content', defaults.metaProperties.title);
			$(defaults.metaGPlusDescription).attr('content', defaults.metaProperties.title);
			$(defaults.metaGPlusImage).attr('content', defaults.metaProperties.image);
		}

		

	};

	return {
		init: init,
		update: update,
		NonVizInit: NonVizInit,
		NonVizUpdate: NonVizUpdate
	};

})(window.socialMedia = window.socialMedia || {});
