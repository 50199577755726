//landing.js shows the landing page
var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var $landing = $('section.landing'),
      $scenes = $('section.scenes'),
      $visualizer = $('div.visualizer-wrapper, footer');

  CHAM.landing = {
    render: function render() {
      $visualizer.fadeOut('slow');
      $scenes.fadeOut('slow');
      $landing.fadeIn('slow');
    },
    events: function events() {
      $landing.on('click', '#select-scene', function() {
        CHAM.router.navigate('/scene');
      });
    }
  };

})(jQuery);
