var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var getQueryParameter = Utilities.getQueryParameter;
  var isCladdingVersion = getQueryParameter('version') === 'cladding';
  CHAM.email = {
    init: function () {
      this.modal = $('#email-modal');
      this.form = this.modal.find('form');
      this.message = this.form.find('[name="body"]');
      this.validator = Forms.FormValidator(this.form, this.formRules);
    },
    formRules: {
      fromEmail: [{ type: 'required', message: 'Please enter your email address.' }, { type: 'email', message: 'Please enter a valid email.' }],
      toEmail: [{ type: 'required', message: 'Please enter a friend\'s email address.' }, { type: 'email', message: 'Please enter a valid email.' }],
      body: [{ type: 'required', message: 'A message is required.' }]
    },
    createMessage: function () {
      var self = this,
          specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec,
          selector = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });

      var url = CHAM.CurrentDomain + '?fblink=' + (specifier || '').replace(/\\/g, '.') + 'seln' + selector;
      if (isCladdingVersion == true) {
        url = url + '&version=cladding';
      }

      self.message.val('Check out this outdoor space I created with ' + CHAM.SiteName + '\'s visualizer! ' + url);
    },
    events: function () {
      var self = this;

      $('#email-btn').on('click', function () {
          self.createMessage();
          self.modal.fadeIn();
      });

      self.modal.on('click', '.close', function () {
        self.modal.fadeOut().promise().done(function () {
          self.validator.resetForm();
        });
      });

      self.form.on('submit', function (e) {
        e.preventDefault();
        if (!self.validator.validateAndPrintMessages())
          return;

        var formValues = self.validator.gatherFormValues();
        formValues.siteId = CHAM.SiteID;

        $.post('/services/email.ashx', formValues);
        self.modal.fadeOut().promise().done(function () {
          self.validator.resetForm();
        });
      });
    }
  };
})(jQuery);
