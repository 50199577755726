//applied.js creates quotes
var $ = jQuery.noConflict(),
    CHAM = CHAM || {};
var parseSelTree = Utilities.parseSelTree;
var getQueryParameter = Utilities.getQueryParameter;

(function ($) {
  'use strict';

  CHAM.applied = {
    init: function () {
      this.container = $('#applied-products-block');
      this.applied = this.container.find('#applied-list');
      this.bodyNode = $('body');
    },
    getPriceParts: function (price) {
      var priceParts = price.split('.');
      var cents = priceParts.length > 1 ? priceParts[1] : '00';

      return { dollars: priceParts[0], cents: cents };
    },
    getProductType: function (surface, product, railingType) {
      if (surface.type === 'Railing')
        return (railingType != null ? railingType + ' ' : '') + surface.type;
      if (surface.type === 'Decking')
        return product.name + ' ' + surface.type;
      if (surface.type === 'Wall')
        return product.categoryName;

      return surface.type;
    },
    createModel: function (specifier, fullSelString, data) {
      var self = this;
      var roomScene = _.find(data.scenes, function (scene) { return scene.specifier === specifier; });
      var railingType = roomScene != null ? roomScene.color : null;
      var isCladdingVersion = getQueryParameter('version') === 'cladding';
      
      var appliedModels = _(Viz.sel.parseToSurfaceArray(fullSelString))
        .zipWith(data.surfaces, function (selector, surface) { return { selector: selector, surface: surface }; })
        .map(function (model) {
          if (model.selector === '-1' || model.surface.type === 'House Trim')
            return null;
          
          var appendProperties = function(product, model) {
            var configuration = _.find(CHAM.DATA.series, function (series) { return series.id === product.seriesID; });

            if (model.surface.type === 'Wall' && configuration.key !== 'fiberonWall')
              return null;

            var surfaceDisplay = configuration.key === 'railing' ? model.surface.type : model.surface.name;
            var productType = self.getProductType(model.surface, product, railingType);
            var price = product.efficiency != null && product.efficiency !== '' ? self.getPriceParts(product.efficiency) : null;
            var thumbnailUrl = product.style != null && product.style !== '' && product.style.search(/(jpg|jpeg|png)/) > -1
              ? '/img/products/' + product.style
              : CHAM.RenderServer + 'GetImage.ashx?type=productswatch&specifier=' + encodeURIComponent(product.specifier) + '&size=420';

            return {
              surfaceDisplay: surfaceDisplay, productType: productType, surfaceType: model.surface.type, product: product,
              price: price, thumbnailUrl: thumbnailUrl
            };
          }

          var sel = parseSelTree(model.selector);
          if (sel[0].children == null) {
            var product = data.productLookup[model.selector];
            if (product == null)
              return null;
            return appendProperties(product, model);
          }

          else if (sel[0].children != null) {
            var productList = [];
            for (var i = 0; i < sel[0].children.length; i++) {
              productList.push(data.productLookup[parseInt(sel[0].children[i].sel)]);
            }

            return _.map(productList, function (product) {
              return appendProperties(product, model);
            });
          }
        })
        .filter(function (model) { return model != null; })
        .value();

      if (isCladdingVersion == false) {
        appliedModels = _.uniqBy(appliedModels, function(model) { return model.surfaceDisplay });
      }
      //console.log("applied models", appliedModels);
      return _.flatMap(appliedModels);
    },
    render: function (applied) {
      var html = CHAM.templates.appliedProducts(applied);
      this.applied.get(0).innerHTML = html;
    },
    events: function (surfaces) {
      var self = this;

      $('#estimate').on('click', function () {
        var specifier = CHAM.visualizer.getCurrentSpecifier();
        var selector = CHAM.visualizer.getCurrentSelector();

        CHAM.DATA.productsPromise.then(function (products) {
          var data = { surfaces: surfaces, scenes: CHAM.DATA.scenes, productLookup: products.lookup };
          var model = self.createModel(specifier, selector, data);

          self.render(model);
          CHAM.quote.updateFormData(model, specifier, selector);
          self.container.fadeIn();
          self.bodyNode.addClass('no-overflow');
        });
      });
    }
  };
})(jQuery);