var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function () {
  'use strict';

  var log = debug('cham:social');

  CHAM.share = {
    config: {
      fbAppID: CHAM.FbAppID,
      imperURL: CHAM.CurrentDomain,
      title: 'Check out this new design of my room I made with the ' + CHAM.SiteName + ' viewer!',
      siteName: CHAM.SiteName,
      siteid: CHAM.SiteID,
      sel: '',
      spec: '',
      vizSelector: '#visualizer',
      hasGPlus: false,
      viz: null,
      FBLinkSelector: '.facebookLinkk',
      TWLinkSelector: '.twitterLinkk',
      PTLinkSelector: '.pinterestLinkk',
      GPlusLinkSelector: '#googleplusLinkk',
      SocialBTNLoc: '#share',
      target: '_blank'
    },
    init: function init() {
      this.shareWrapper = $('#share-options');
      this.shareImage = this.shareWrapper.find('#share-image');
      this.shareButton = $('#share');
      this.emailDialogElement = $('#email-modal').get(0);
      this.bodyNode = $('body');
      this.social();
      this.initComplete = true;
    },
    initComplete: false,
    social: function social() {
      socialMedia.init(CHAM.share.config);
    },
    tryCloseShare: function () {
      if (!this.isOpen)
        return;

      this.closeShare();
    },
    closeShare: function () {

      // The only purpose of the invisible class is to prevent a transition from occuring when switching to/from mobile breakpoints.
      var self = this;
      var shareWrapper = this.shareWrapper;
      this.shareButton.removeClass('active');

      shareWrapper.removeClass('active');
      shareWrapper.one(CHAM.transitionEnd, function () {
        self.bodyNode.removeClass('no-overflow');
        shareWrapper.addClass('invisible');

        shareWrapper.off(CHAM.transitionEnd); // Must remove, will fire twice in chrome.
      });

      this.isOpen = false;
    },
    openShare: function () {
      this.shareButton.addClass('active');
      this.shareWrapper.removeClass('invisible').addClass('active');
      this.bodyNode.addClass('no-overflow');

      this.isOpen = true;
    },
    events: function () {
      this.shareWrapper = $('#share-options');
      this.shareButton = $('#share');
      var self = this;
      var wrapperElement = self.shareWrapper.get(0);

      self.shareButton.off('click').on('click', function (e) {
        e.stopPropagation();

        if (self.isOpen)
          self.closeShare();
        else {
          var specifier = CHAM.visualizer.getCurrentSpecifier();
          var selector = CHAM.visualizer.getCurrentSelector();
          var url = CHAM.RenderServer + 'GetImage.ashx?size=250&specifier=' + encodeURIComponent(specifier)
            + (selector != null && selector !== '' ? '&sel=' + encodeURIComponent(selector) : '');

          self.shareImage.attr('src', url);
          self.update();
          self.openShare();
          kenshoo_conv('share', '', '', '', 'USD');
        }
      });

      self.shareWrapper.on('click', '.close', self.closeShare.bind(self));

      $(document).on('click', function (e) {
        var targetNode = $(e.target);

        if (!self.isOpen || targetNode.is(wrapperElement) || targetNode.is(self.emailDialogElement)
          || $.contains(wrapperElement, e.target) || $.contains(self.emailDialogElement, e.target))
          return;

        self.closeShare();
      });

      $(".share-link").on("click", function () {
        CHAM.helpers.getShortURL(CHAM.VIZ.ChamViz({ action: 'test' }).spec, CHAM.VIZ.ChamViz({ action: 'getFullSelString' }), function (url) {
          $("#shareURL").val(url);
          CHAM.integration.logModalOpen("copy-url");
          $("#copy-modal").show();
          CHAM.integration.scrollParentToTop();
        });
      });

      $("#twitter-link").on("click", function () {
        console.log("11");
        CHAM.helpers.getShortURL(CHAM.VIZ.ChamViz({ action: 'test' }).spec, CHAM.VIZ.ChamViz({ action: 'getFullSelString' }), function (shortUrl) {
          console.log("222");
          window.open("https://twitter.com/intent/tweet?text=" + encodeURIComponent('Check out this new design of my room I made with the ' + CHAM.SiteName + ' viewer!') + " " + shortUrl);
        });
      });

      $("#pinterest-link").on("click", function () {
        var spec = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
        var sel = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
        CHAM.helpers.getShortURL(spec, sel, function (shortUrl) {
          window.open("https://pinterest.com/pin/create/button/?url=" + encodeURIComponent(shortUrl) +
            "&description=" + encodeURIComponent('Check out this new design of my room I made with the ' + CHAM.SiteName + ' viewer!') +
            "&media=" + encodeURIComponent(
              'https://core2render.chameleonpower.com/cham/GetImage.ashx?spec=' + spec + '(focus=300)&sel=' + sel + ' &Size=512&type=base'));
        });
      });

      $("#facebook-link").on("click", function (event) {
        FB.ui(
          {
            method: 'share',
            href: $(".facebookLink").attr("href"),

          }, function (response) { });
      });

      $("#copy-modal .copyToClipboard").on("click", function () {
        $("#copy-modal #shareURL").select();

        try {
          document.execCommand('copy');
          alertify.alert("URL copied");
          CHAM.integration.scrollParentToTop();
        }
        catch (err) {
          alertify.alert('Unable to copy to clipboard, your browser may not support this feature. You will have to copy this link manually.');
        }
      });

      $("#copy-modal .close, #copy-modal .cancel").on("click", function () {
        $("#copy-modal").fadeOut();
      });
    },
    update: function update() {
      if (!this.initComplete) {
        this.init();
      }
      var specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
      var selString = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
      var ogLink = "https://" + 'fiberon '+ ".chameleonpower.com/og_meta.aspx?Specifier=" +

       specifier.replace(/\\/g, "%5C") +
        "~" +
        selString
      //var imageLink = "https://beaulieu.chameleonpower.com/og_pic.ashx";

      var fbURL = "https://www.facebook.com/dialog/feed?app_id=" +
        CHAM.FbAppID +
        "&link=" +
        ogLink +
        "&redirect_uri=" +
        ogLink
      ;
      $(".facebookLink").attr("href", fbURL);
    }
  };
})();
