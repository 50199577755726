var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  CHAM.DATA = {};

  CHAM.services = {

    lizard: '/services/jsonProxy.ashx?remoteURL=https://lizard.chameleonpower.com/',

    getScenesBySiteID: function () {
      return fetch(this.lizard + 'RoomScene/SiteID/' + CHAM.SiteID).then(function(response) {
        return response.json();
      });
    },
    getScenesBySeriesID: function (id) {
      return fetch(this.lizard + 'RoomScene/SeriesID/' + id).then(function(response) {
        return response.json();
      });
    },
    getProductsBySeriesID: function (id) {
      return fetch(this.lizard + 'Product/Series/' + id).then(function (response) {
        return response.json();
      });
    },
    getProductsBySeriesIDs: function (ids) {
      var idsCsv = ids.join(',');

      return fetch(this.lizard + 'Product/SeriesList/' + idsCsv)
        .then(function (response) { return response.json(); })
        .then(function (products) {
          var series = _.groupBy(products, function (product) { return product.seriesID; });
          var lookup = _.keyBy(products, function (product) { return product.id; });

          return { series: series, lookup: lookup }; // Lookup is for the applied products tray. It is also used in the product menus.
        });
    }
  };
})(jQuery);