/* Forms Module Documentation
 * --------------------------
 *
 * -- Form Rules --
 * {} with
 *     - key: name of the form input 
 *     - value: { type: string, message?: string, options?: any }[]
 *         - Only type is required
 *         - The options will be different depending on the validator type. For example, extension type takes an string[] of file extensions as the options.
 *
 * Example:
 *     var rules = {
 *         email: [{ type: 'required', message: 'Please enter a friend\'s email address.' }, { type: 'email', message: 'Please enter a valid email.' }],
 *         body: [{ type: 'required', message: 'A message is required.' }]
 *     };
 *
 * -- Custom Validators --
 * Custom validators are possible using type: 'custom'. The options property then will accept a custom validator function.
 * Example showing validation of csv string of emails (note this example has a dependency on lodash):
 * 
 * var emailRegex = Forms.emailRegex;
 * var emailValidator = function (node) {
 *     return _(node.val().split(','))
 *         .map(function (email) { return email.trim(); })
 *         .every(function (email) { return emailRegex.test(email); });
 * };
 *
 * var rules = [
 *     { type: 'required', message: 'Please enter one or more email addresses.' },
 *     { type: 'custom', options: emailValidator, message: 'Email addresses must be valid emails and separated by a comma.' }
 * ];
 *
 * The signature of a custom validator is as follows: (node: JQuery, lookup: Object, options: any) => boolean
 * 
 * node: JQuery - The input being validated
 * lookup: { key: string, value: JQuery }
 *     - All the inputs of the form
 *     - key is the name of the form input
 *     - value is the input node
 *     - This parameter is useful for implementing a "required if" custom validator.
 * options: any - The options property set in the form rules declaration.
 *
 */

var Forms = (function () {
  var lazy = Utilities.lazy;
  var emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
  var defaultValidationError = 'Field is not valid.';

  var validators = {
    input: {
      text: {
        required: function (node) {
          return node.val() !== '';
        },
        email: function (node) {
          return emailRegex.test(node.val());
        }
      },
      password: {
        required: function (node) {
          return node.val() !== '';
        }
      },
      file: {
        required: function (node) {
          return node.val() !== '';
        },
        extension: function (node, lookup, options) {
          var fileName = node.val();
          if (fileName === '')
            return true;

          var parts = fileName.split('.');
          var extension = parts[parts.length - 1].toLowerCase();

          for (var index = 0; index < options.length; index++)
            if (options[index] === extension)
              return true;

          return false;
        }
      }
    },
    select: {
      required: function (node) {
        return node.prop('selectedIndex') !== 0;
      }
    },
    textarea: {
      required: function (node) {
        return node.val() !== '';
      }
    }
  };

  function buildNodeValidators(nodeName, nodeType, validationRules) {
    var validatorGroup = nodeName === 'input' ? validators[nodeName][nodeType] : validators[nodeName];
    var nodeValidators = [];

    if (validatorGroup == null)
      return nodeValidators;

    for (var index = 0; index < validationRules.length; index++) {
      var rule = validationRules[index];
      var validator = rule.type === 'custom' ? rule.options : validatorGroup[rule.type];

      if (validator != null)
        nodeValidators.push({ isValid: validator, options: rule.options, message: rule.message || defaultValidationError });
    }

    return nodeValidators;
  }

  function mergeValidationRules(inputDefaultRules, validationTypes, validationMessages) {
    var rules = inputDefaultRules || [];

    for (var index = 0; index < validationTypes.length; index++) {
      var type = validationTypes[index];
      var message = validationMessages[index];
      var existingRule = null;

      for (var innerIndex = 0; innerIndex < rules.length; innerIndex++) {
        var rule = rules[innerIndex];

        if (type === rule.type) {
          existingRule = rule;
          break;
        }
      }

      if (existingRule == null)
        rules.push({ type: type, options: null, message: message });
      else if (message != null)
        existingRule.message = message;
    }

    return rules;
  }

  function buildValidationRules(formNode, formRules) {
    var inputElements = formNode.find('input[type="text"], input[type="password"], input[type="file"], select, textarea').get();
    var formLookup = {};
    var formInputs = [];

    for (var index = 0; index < inputElements.length; index++) {
      var inputNode = $(inputElements[index]);
      var typesData = inputNode.data('validation');
      var messagesData = inputNode.data('validation-msg');
      var nodeName = inputNode.prop('nodeName').toLowerCase();
      var nodeType = inputNode.prop('type').toLowerCase();

      var inputDefaultRules = formRules != null ? formRules[inputNode.prop('name')] : null;
      var types = typesData != null && typesData !== '' ? typesData.split('|') : [];
      var messages = messagesData != null && messagesData !== '' ? messagesData.split('|') : [];

      var rules = mergeValidationRules(inputDefaultRules, types, messages);

      formLookup[inputNode.prop('name')] = inputNode;
      formInputs.push({
        inputNode: inputNode,
        validationNode: lazy(function (inputNode) {
          return $('<span class="validation-error" style="display: none;"></span>').insertAfter(inputNode);
        }.bind(null, inputNode)),
        validators: buildNodeValidators(nodeName, nodeType, rules)
      });
    }

    return { formLookup: formLookup, formInputs: formInputs };
  }

  var FormValidator = function (formNode, formRules) {
    /// <signature>
    /// <summary>Wraps a form providing validation, displaying of error messages, form resetting, and gathering of form values.</summary>
    /// <param name="formNode" type="JQuery">JQuery object containing the form element. The form element must be a form tag.</param>
    /// <param name="formRules" type="Object">An object literal following the signature { key: string, value: { type: string, message?: string, options?: any }[] }</param>
    /// </signature>

    if (!(this instanceof FormValidator))
      return new FormValidator(formNode, formRules);

    var formData = buildValidationRules(formNode, formRules);

    this.formLookup = formData.formLookup;
    this.formInputs = formData.formInputs;
    this.inputsLength = formData.formInputs.length;
    this.formNode = formNode;
    this.formElement = formNode.get(0);
    this.isInDom = false;
  }

  FormValidator.prototype.validate = function () {
    var isAllValid = true;
    var errorMessages = [];

    for (var index = 0; index < this.inputsLength; index++) {
      var formInput = this.formInputs[index];

      for (var innerIndex = 0; innerIndex < formInput.validators.length; innerIndex++) {
        var validator = formInput.validators[innerIndex];

        if (!validator.isValid(formInput.inputNode, this.formLookup, validator.options)) {
          errorMessages.push(validator.message);
          isAllValid = false;
          break;
        }
      }

      if (this.isInDom)
        formInput.validationNode.value.css('display', 'none');
    }

    return { isValid: isAllValid, errorMessages: errorMessages };
  }

  FormValidator.prototype.validateAndPrintMessages = function () {
    this.isInDom = true;
    var isAllValid = true;

    for (var index = 0; index < this.inputsLength; index++) {
      var formInput = this.formInputs[index];
      var isValid = true;

      for (var innerIndex = 0; innerIndex < formInput.validators.length; innerIndex++) {
        var validator = formInput.validators[innerIndex];

        if (!validator.isValid(formInput.inputNode, this.formLookup, validator.options)) {
          formInput.validationNode.value.text(validator.message);
          isValid = isAllValid = false;
          break;
        }
      }

      formInput.validationNode.value.css('display', isValid ? 'none' : 'block');
    }

    return isAllValid;
  }

  FormValidator.prototype.resetForm = function () {
    this.formElement.reset();

    if (this.isInDom)
      for (var index = 0; index < this.inputsLength; index++)
        this.formInputs[index].validationNode.value.css('display', 'none');
  }

  FormValidator.prototype.gatherFormValues = function () {
    var formArray = this.formNode.serializeArray();
    var formValues = {};

    for (var index = 0; index < formArray.length; index++) {
      var formItem = formArray[index];
      formValues[formItem.name] = formItem.value !== '' ? formItem.value : null;
    }

    return formValues;
  }

  return {
    FormValidator: FormValidator,
    emailRegex: emailRegex
  };
})();