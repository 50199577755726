//scenes.js  gets images from the database and displays them as a list. 
//clicking an image makes it active
//clicking the "Get Started" button redirects to the visualizer
var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var getQueryParameter = Utilities.getQueryParameter;
  var isCladdingVersion = getQueryParameter('version') === 'cladding';

  var $mainTitle = $('#main-title'),
      $scenes = $('section.scenes, nav.landing'),
      $visualizer = $('div.visualizer-wrapper'),
      bigScene = $('#big-scene').get(0);

  CHAM.scenes = {
    data: function () {
      var self = this;
      var seriesId = isCladdingVersion ? 6574 : 6106;

      this.promise = CHAM.services.getProductsBySeriesID(seriesId).then(function (scenes) {
        var filtered = _.filter(scenes, function (scene) { return scene.color === 'Symmetry'; });

        CHAM.DATA.scenes = scenes;
        self.filteredScenes = filtered;
        return filtered;
      });
    },
    renderBigScene: function (scene) {
      this.currentScene = scene;

      var url = CHAM.RenderServer + 'GetImage.ashx?size=900&specifier=' + encodeURIComponent(scene.specifier);
      bigScene.innerHTML = '<img src="' + url + '" />';
    },
    render: function () {
      var self = this;
      $mainTitle.text(isCladdingVersion ? 'Exterior Cladding Visualizer' : 'Outdoor Living Visualizer');

      this.promise.then(function (scenes) {
        var html = CHAM.templates.scenes(scenes);
        document.getElementById('roomscene-data').innerHTML = html;

        if (scenes.length > 0)
            self.renderBigScene(scenes[0]);
      });

      $visualizer.hide();
      $scenes.fadeIn('slow');
    },
    events: function () {
      var self = this;

      $scenes.on('click', '#roomscene-data li', function () {
        var clicked = $(this);
        var index = clicked.data('index');
        var scene = self.filteredScenes[index];

        self.renderBigScene(scene);
        clicked.addClass('active').siblings().removeClass('active');
      });

      $scenes.on('click', '#get-started', function () {
        kenshoo_conv('visualizer', '', '', '', 'USD');

        if (self.currentScene == null)
          return;

        var url = '/scene/'
          + encodeURIComponent(self.currentScene.specifier)
          + '/products/-1';

        CHAM.router.navigate(url);
      });
    }
  };
})(jQuery);