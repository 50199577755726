'use strict';

var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  var getQueryParameter = Utilities.getQueryParameter;
  var isCladdingVersion = getQueryParameter('version') === 'cladding';
  
  CHAM.SiteID = SITEID;// Set in Master Page
  CHAM.SiteName = 'Fiberon';
  CHAM.GoogleAnalytics = 'UA-XXXXX-Y';
  CHAM.FbAppID = isCladdingVersion ? '1827289110641958' : '285239138570055';
  CHAM.HighlightColor = 'EE2E24';
  CHAM.transitionEnd = 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';

  CHAM.DATA.series = [
    { id: 6104, key: 'stain', surfaceType: 'Decking', surfaceNames: ['Deck', 'Accent', 'Stair Risers', 'Bench'] },
    { id: 6161, key: 'stain', surfaceType: 'Decking', surfaceNames: ['Fascia'] },
    { id: 6113, key: 'railing', surfaceType: 'Railing', surfaceNames: ['Top Rail', 'Bottom Rail'] },
    { id: 6114, key: 'balusters', surfaceType: 'Railing', surfaceNames: ['Balusters'] },
    { id: 6115, key: 'postCaps', surfaceType: 'Railing', surfaceNames: ['Post Cap'] },
    { id: 6117, key: 'postMouldings', surfaceType: 'Railing', surfaceNames: ['Post Skirt'] },
    { id: 6121, key: 'postLights', surfaceType: 'Lighting', surfaceNames: ['Post Lights'] },
    { id: 6120, key: 'riserLights', surfaceType: 'Lighting', surfaceNames: ['Riser Lights', 'Stair Riser Lights'] },
    { id: 6160, key: 'accentLights', surfaceType: 'Lighting', surfaceNames: ['Accent Lights'] },
    { id: 6619, key: 'fiberonWall', surfaceType: 'Wall', surfaceNames: ['Exterior Wall', 'Front Walls', 'Back Walls', 'Back Wall', 'Left Wall', 'Right Walls', 'Colonnade Center', 'Colonnade Soffit'] },
    { id: 6128, key: 'siding', surfaceType: 'Wall', surfaceNames: ['Exterior Wall', 'Front Walls', 'Back Walls', 'Back Wall', 'Left Wall', 'Right Walls', 'Colonnade Center', 'Colonnade Soffit'] },
    { id: 6127, key: 'brick', surfaceType: 'Wall', surfaceNames: ['Exterior Wall', 'Front Walls', 'Back Walls', 'Back Wall', 'Left Wall', 'Right Walls', 'Colonnade Center', 'Colonnade Soffit'] },
    {
      id: 6126, key: 'trim', surfaceType: 'House Trim',
      surfaceNames: ['House Trim', 'Center Wall', 'Front Walls Trim', 'Back Walls Trim', 'Garage Door', 'Bench Legs', 'Awning', 'Pergola', 'Roof', 'Doors', 'Trim', 'Colonnade']
    }
  ];

  CHAM.DATA.colorFamilies = {
    'Classic Browns': { hexColor: '#664229', sortOrder: 1 },
    'Golden Browns': { hexColor: '#C59D72', sortOrder: 2 },
    'Reddish Browns': { hexColor: '#72220F', sortOrder: 3 },
    'Grays': { hexColor: '#BEC0C2', sortOrder: 4 }
  };

  CHAM.DATA.deckingTypes = {
    'Symmetry': {
      header: 'Saturated color, unique matte finish',
      body: 'Random graining with no visible patterns make it indistinguishable from wood. Four-sided capping ensures stain and fade resistance. Composite core means no cracking, rotting, or decay. Finished surface on top and bottom is idea for second-story decks.',
      sortOrder: 1
    },
    'Horizon': {
      header: 'Bold, multi-tonal streaking and four distinct grain patterns',
      body: 'Four-sided capping ensures stain and fade resistance. Composite core means no cracking, rotting, or decay. Reversible boards provide a polished appearance from all angles. Price comparable to other\'s three-sided boards.',
      sortOrder: 2
    },
    'Paramount': {
      header: 'Ultra-resilient PVC',
      body: 'Withstands moisture, dents, insects -- even flames. Lightweight, easy to handle, and meets OSHA and ADA slip-resistance guidelines. Ideal for docks or waterside applications (no organic content to promote mold growth).',
      sortOrder: 3
    },
    'ProTect Advantage': {
      header: 'All-around performer',
      body: 'High-end look at an affordable price. Subtle, multi-tonal streaked boards emulate premium hardwoods. Three-sided capping make it a great choice for lower-level decks. ProTect Advantage FR is approved for Wildland Urban Interface (WUI) zones.',
      sortOrder: 4
    },
    'Sanctuary': {
      header: 'Rustic and refined',
      body: 'Sanctuary features a high-traction wood grain, so you\'ll stay steady on your feet. Three-sided capping ensures stain and fade resistance. Composite core means no cracking, rotting, or decay. Available in select locations.',
      sortOrder: 5
    },
    'Good Life': {
      header: 'Get more for your money',
      body: 'Durable and long-lasting, at a price point that\'s closer to wood. Capped on 3 sides to resist staining and fading. Three solid color options that never need painting.',
      sortOrder: 6
    }
  };

  CHAM.DATA.railingTypes = {
    'Symmetry': {
      header: 'Strong, stylish and sculpted',
      body: 'Satin finish. Durable composite construction. Reinforced top rail provides extra stability.  Customizable infill options. Industry\'s only 12-ft span means better sight lines, fewer posts. Hidden hardware keeps the look clean.',
      sortOrder: 1
    },
    'Horizon': {
      header: 'Engineered for enjoyment',
      body: 'Sleek, flat top rail is ideal for resting a drink. Solid composite construction provides lasting durability, while permanent bonded finish protects against moisture, stains, insects, and UV rays. Versatile dagger system enables fast installation. Easily customizable with multiple infill options and Fiberon lighting.',
      sortOrder: 2
      },
    'Brio Rhythm': {
          header: '',
          body: '',
          sortOrder: 3
      },
  };

  CHAM.RenderServer = '//core2render.chameleonpower.com/cham/';
  CHAM.CurrentDomain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

  CHAM.CookieMessage = '<strong>Please Enable Cookies</strong><br /><br /> \
    Your browser\'s cookie functionality is turned off. Please turn it on to use this site.<br /><br /> \
    <em>If you are using Safari, make sure 3rd party cookies are enabled.</em> \
  ';

  if (CHAM.SiteID === '[SITEID]') console.warn('INVALID SITE ID: Make sure to update your configuration(s)');

})(jQuery);