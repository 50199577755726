// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

/* ==========================================================================
   Loading Msg
   ========================================================================== */

var LoadingMsg = (function () {
    var settings = { prefix: 'loading_', offset: 0, className: 'loading-msg', img: '', zindex: 999 };

    function center(parent, child, offset) {
        parent = $(parent);
        child = $(child);
        child.css('left', ((parent.innerWidth() - child.outerWidth()) >>> 1) + offset);
        child.css('top', ((parent.innerHeight() - child.outerHeight()) >>> 1) + offset);
    };

    function start(id, anchor, msg) {
        var s = settings;
        id = id.replace(/\s/g, '');

        var count = $('.' + s.className).length;
        var div = $('<div><div class="uil-ellipsis-css" style="transform:scale(0.4);"><div class="ib"><div class="circle"><div></div></div><div class="circle"><div></div></div><div class="circle"><div></div></div><div class="circle"><div></div></div></div></div></div>')
          .attr("id", s.prefix + id).addClass(s.className).css('z-index', s.zindex + count);

        if (msg) {
            var inner = $('<div></div>').addClass(s.className + '-inner').html(msg);
            div.append(inner);
        }
        anchor = $(anchor).append(div);
        center(anchor, div, 0);
    };

    function stop(id) {
        id = id.replace(/\s/g, '');
        $('#' + settings.prefix + id).remove();
    };

    function loadImg(src, id, anchor, msg) {
        var prevId = $(imgEl).attr('data-loadingId');
        if (prevId != null && prevId != '') stop(prevId);
        start(id, anchor, msg);
        $(imgEl).attr('data-loadingId', id);
        var img = new Image();
        $(img).bind('load abort error', function (e) {
            stop($(this).attr('data-loadingId'));
            $(this).unbind(e);
        }).attr('src', src);
    };

    function startImg(imgEl, uniqueId, anchor, msg) {
        var prevId = $(imgEl).attr('data-loadingId');
        if (prevId != null && prevId != '') stop(prevId);
        start(uniqueId, anchor, msg);
        $(imgEl).attr('data-loadingId', uniqueId);
        $(imgEl).bind('load abort error', function (e) {
            stop($(this).attr('data-loadingId'));
            $(this).unbind(e);
        });
    };

    var Queue = {
        count: 0,
        msgId: '',
        loadImg: function loadImg(imgEl, id, anchor, msg) {
            var prevId = $(imgEl).attr('data-loadingId');
            if (prevId != null && prevId != '') stop(prevId);
            start(id, anchor, msg);


            $(imgEl).attr('data-loadingId', id);
            $(imgEl).bind('load abort error', function () {
                stop($(this).attr('data-loadingId'));
                $(this).unbind();
            });
        }
    };

    return {
        settings: settings,
        center: center,
        start: start,
        stop: stop,
        loadImg: loadImg,
        startImg: startImg,
        Queue: Queue
    };
})();


/*
 CSS Browser Selector js v0.5.3 (July 2, 2013)
 -- original --
 Rafael Lima (http://rafael.adm.br)
 http://rafael.adm.br/css_browser_selector
 License: http://choosealicense.com/licenses/mit/
 Contributors: http://rafael.adm.br/css_browser_selector#contributors
 -- /original --
 Fork project: http://code.google.com/p/css-browser-selector/
 Song Hyo-Jin (shj at xenosi.de)
 */

function css_browser_selector(u) {
  var ua = u.toLowerCase(),
    is = function(t) {
      return ua.indexOf(t) > -1
    },
    g = 'gecko',
    w = 'webkit',
    s = 'safari',
    c = 'chrome',
    o = 'opera',
    m = 'mobile',
    v = 0,
    r = window.devicePixelRatio ? (window.devicePixelRatio + '').replace('.', '_') : '1';
  var res = [
    /* IE */
    (!(/opera|webtv/.test(ua)) && /msie\s(\d+)/.test(ua) && (v = RegExp.$1 * 1)) ?
      ('ie ie' + v + ((v == 6 || v == 7) ?
        ' ie67 ie678 ie6789' : (v == 8) ?
        ' ie678 ie6789' : (v == 9) ?
        ' ie6789 ie9m' : (v > 9 ) ?
        ' ie9m' : '')) :
      /* EDGE */
      (/edge\/(\d+)\.(\d+)/.test(ua) && (v = [RegExp.$1, RegExp.$2])) ?
      'ie ie' + v[0] + ' ie' + v[0] + '_' + v[1] + ' ie9m edge' :
        /* IE 11 */
        (/trident\/\d+.*?;\s*rv:(\d+)\.(\d+)\)/.test(ua) && (v = [RegExp.$1, RegExp.$2])) ?
        'ie ie' + v[0] + ' ie' + v[0] + '_' + v[1] + ' ie9m' :
          /* FF */
          (/firefox\/(\d+)\.(\d+)/.test(ua) && (re = RegExp)) ?
          g + ' ff ff' + re.$1 + ' ff' + re.$1 + '_' + re.$2 :
            is('gecko/') ? g :
              /* Opera - old */
              is(o) ? 'old' + o + (/version\/(\d+)/.test(ua) ? ' ' + o + RegExp.$1 :
                (/opera(\s|\/)(\d+)/.test(ua) ? ' ' + o + RegExp.$2 : '')) :
                /* K */
                is('konqueror') ? 'konqueror' :
                  /* Black Berry */
                  is('blackberry') ? m + ' blackberry' :
                    /* Chrome */
                    (is(c) || is('crios')) ? w + ' ' + c :
                      /* Iron */
                      is('iron') ? w + ' iron' :
                        /* Safari */
                        !is('cpu os') && is('applewebkit/') ? w + ' ' + s :
                          /* Mozilla */
                          is('mozilla/') ? g : '',
    /* Android */
    is('android') ? m + ' android' : '',
    /* Tablet */
    is('tablet') ? 'tablet' : '',
    /* blink or webkit engine browsers */
    /* Opera */
    is('opr/') ? o : '',
    /* Yandex */
    is('yabrowser') ? 'yandex' : '',
    /* Machine */
    is('j2me') ? m + ' j2me' :
      is('ipad; u; cpu os') ? m + ' chrome android tablet' :
        is('ipad;u;cpu os') ? m + ' chromedef android tablet' :
          is('iphone') ? m + ' ios iphone' :
            is('ipod') ? m + ' ios ipod' :
              is('ipad') ? m + ' ios ipad tablet' :
                is('mac') ? 'mac' :
                  is('darwin') ? 'mac' :
                    is('webtv') ? 'webtv' :
                      is('win') ? 'win' + (is('windows nt 6.0') ? ' vista' : '') :
                        is('freebsd') ? 'freebsd' :
                          (is('x11') || is('linux')) ? 'linux' : '',
    /* Ratio (Retina) */
    (r != '1') ? ' retina ratio' + r : '',
    'js portrait'].join(' ');
  if(window.jQuery && !window.jQuery.browser) {
    window.jQuery.browser = v ? {msie: 1, version: v} : {};
  }
  return res;
};
(function(d, w) {
  var _c = css_browser_selector(navigator.userAgent);
  var h = d.documentElement;
  h.className += ' ' + _c;
  var _d = _c.replace(/^\s*|\s*$/g, '').split(/ +/);
  w.CSSBS = 1;
  for(var i = 0; i < _d.length; i++) {
    w['CSSBS_' + _d[i]] = 1;
  }
  var _de = function(v) {
    return d.documentElement[v] || d.body[v];
  }
  if(w.jQuery) {
    (function($) {
      var p = 'portrait', l = 'landscape';
      var m = 'smartnarrow', mw = 'smartwide', t = 'tabletnarrow', tw = 'tabletwide', ac = m + ' ' + mw + ' ' + t + ' ' + tw + ' pc';
      var $h = $(h);
      var to = 0, cw = 0;

      /* ie7 cpu 100% fix */
      function CSSSelectorUpdateSize() {
        if(to != 0) return;
        try {
          var _cw = _de('clientWidth'), _ch = _de('clientHeight');
          if(_cw > _ch) {
            $h.removeClass(p).addClass(l);
          } else {
            $h.removeClass(l).addClass(p);
          }
          if(_cw == cw) return;
          cw = _cw;
          //clearTimeout(to);
        } catch(e) {
        }
        to = setTimeout(CSSSelectorUpdateSize_, 100);
      }

      function CSSSelectorUpdateSize_() {
        try {
          $h.removeClass(ac);
          $h.addClass(
            (cw <= 360) ? m :
              (cw <= 640) ? mw :
                (cw <= 768) ? t :
                  (cw <= 1024) ? tw : 'pc'
          );
        } catch(e) {
        }
        to = 0;
      }

      if(w.CSSBS_ie) {
        setInterval(CSSSelectorUpdateSize, 1000);
      } else {
        $(w).on('resize orientationchange', CSSSelectorUpdateSize).trigger('resize');
      }
      $(w).on('load', (CSSSelectorUpdateSize));
    })(w.jQuery);
  }
})(document, window);
