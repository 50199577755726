var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  CHAM.templates = {
    scenes: function (scenes) {
      return CHAM.compiled.scene({ scenes: scenes });
    },
    menu: function (surfaceGroups) {
      return CHAM.compiled['menu/surface-menu']({ surfaceGroups: surfaceGroups });
    },
    productMenuWrapper: function (displayName, surfaceNumbers, bodyHtml) {
      return CHAM.compiled['products/menu-wrapper']({ displayName: displayName, surfaceNumbers: surfaceNumbers.join(','), bodyHtml: bodyHtml });
    },
    appliedProducts: function (appliedModels) {
      return CHAM.compiled['products/applied']({ appliedModels: appliedModels });
    },
    deckProducts: function (model) {
      return CHAM.compiled['products/deck'](model);
    },
    railingProducts: function (model) {
      var clone = _.assign({}, model);
      clone.convertCfxToCssColor = CHAM.helpers.convertCfxToCssColor;
        clone.getCurrentProductHtml = CHAM.templates.railingCurrentProduct;
      return CHAM.compiled['products/railing'](clone);
    },
    railingCurrentProduct: function (product) {
      return CHAM.compiled['products/railing-current']({ product: product });
    },
    lightingProducts: function (model) {
      var clone = _.assign({}, model);
      clone.convertCfxToCssColor = CHAM.helpers.convertCfxToCssColor;

      return CHAM.compiled['products/lighting'](clone);
    },
    lightingCurrentProduct: function (product) {
      return CHAM.compiled['products/lighting-current']({ product: product });
    },
    wallProducts: function (model) {
      return CHAM.compiled['products/wall'](model);
    },
    houseTrimProducts: function (model) {
      return CHAM.compiled['products/house-trim'](model);
    },
    deckTypeInfo: function (typeInfo) {
      return '<h4>' + typeInfo.header + '</h4><p>' + typeInfo.body + '</p>';
    },
    defaultZoom: function (product, title) {
      return CHAM.compiled['zoom/default-zoom']({ product: product, title: title });
    },
    wideZoom: function (product, title, descriptionItems) {
      return CHAM.compiled['zoom/wide-zoom']({ product: product, title: title, descriptionItems: descriptionItems });
    },
    blendMenu: function (appliedProducts) {
      return CHAM.compiled['products/blend-menu']({appliedProducts: appliedProducts});
    }
  };
})(jQuery);