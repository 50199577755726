var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var $scenes = $('section.scenes, nav.landing'),
      $visualizer = $('div.visualizer-wrapper'),
      parseSelTree = Utilities.parseSelTree;

  CHAM.VIZ = $(document.getElementById('visualizer'));

  var loadingViz = function () {
    var img = document.createElement('img');
    $(img).css({ position: 'absolute', left: 0, top: 0, 'z-index': 0 });
    LoadingMsg.startImg(img, 'loadViz', document.getElementsByClassName("visualizer"), null);
  };

  CHAM.visualizer = {
    isZoomed: false,
    init: function (specifier, selector) {
      $visualizer.css({ display: 'flex', position: 'absolute', opacity: 0 });
      this.isIM = !!specifier.includes('_USER_');

      var config = _.assign({ surfacesLoaded: CHAM.initialize.surfacesLoaded }, this.createConfig(specifier, selector));
      CHAM.VIZ.ChamViz(config);

     
      window.setTimeout(function () {
          $scenes.hide();
          $visualizer.css('position', '').animate({ opacity: 1 }, 'slow');
      }, 400);
    },
    initRailingType: function (specifier, selector) {
      var config = this.createConfig(specifier, selector);
      loadingViz();
      CHAM.VIZ.ChamViz(config);
    },
    createConfig: function (specifier, selector) {
      return {
        spec: specifier,
        sel: selector,
        defaultSpec: specifier,
        defaultSel: selector,
        siteid: CHAM.SiteID,
        hightlightColor: CHAM.HighlightColor,
        isResponsive: true,
        responsiveMode: 'fit width',
        responsiveAlignment: 'left top',
        showMouseOvers: true,
        renderserver: CHAM.RenderServer,
        zoomWidth: 2500,
        AllSurfacesLoaded: CHAM.initialize.AllSurfacesLoaded,
        chooseSurfaceHandler: function (surface) {
          var surfaceNumber = surface.specifiersurfacenumber;

          CHAM.share.tryCloseShare();
          CHAM.menu.toggleSurfaceGroup([surfaceNumber]);
        },
        applySurfaceHandler: function (img, surfaceObj, chamVizContainer, productName, sel) {
          if (sel != '-1' || sel != '')
            LoadingMsg.startImg(img, 'Loading' + surfaceObj.sortorder, this, productName);
        },
        initComplete: function () {
          CHAM.initialize.initComplete();
          LoadingMsg.stop("loadViz");
        },
      };
    },
    getCurrentData: function () {
      var test = CHAM.VIZ.ChamViz({ action: 'test' });

      return {
        specifier: test.spec,
        selector: CHAM.VIZ.ChamViz({ action: 'getFullSelString' }),
        surfaces: test.surfaces
      };
    },
    getCurrentSpecifier: function () {
      return CHAM.VIZ.ChamViz({ action: 'test' }).spec;
    },
    getCurrentSelector: function () {
      return CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
    },
    apply: function (surface, sel) {
      CHAM.VIZ.ChamViz({ action: 'unzoom' });
      this.isZoomed = false;

      CHAM.VIZ.ChamViz({
        action: 'apply',
        surface: surface,
        sel: sel
      });
    },
    applyByFilter: function (func) {
      CHAM.VIZ.ChamViz({ action: 'unzoom' });
      this.isZoomed = false;

      CHAM.VIZ.ChamViz({
        action: 'applyByFilter',
        fn: func
      });
    },
    applyFullSel: function (fullSel, displayName) {
      CHAM.VIZ.ChamViz({ action: 'unzoom' });
      this.isZoomed = false;

      var arr = Viz.sel.parseToSurfaceArray(fullSel);
      CHAM.VIZ.ChamViz({
        action: 'applyByFilter',
        sel: '',
        productName: displayName,
        fn: function (surface, newSel, currentSel) {
          var sel = arr[surface.sortorder - 1];
          if (sel == void 0) return '-1';
          else return sel;
        }
      });
    },
    updateURL: function () {
      var specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
      var selector = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
      var url = '/scene/' + specifier + '/products/' + selector;

      if (window.location.hash.replace('#', '') === url)
        return;

      CHAM.router.pause();
      CHAM.router.navigate(url);

      $(window).one('hashchange', function () {
        CHAM.router.resume();
      });
    },
    events: function () {
      var self = this;
      var controls = $('#visualizer-controls');
      
      var highlightSelectedProduct = function () {
        var productMenus = $('#product-menus .product-menu');
        var selector = parseSelTree(CHAM.VIZ.ChamViz({ action: 'getFullSelString' }));

        for (var i = 0; i < productMenus.length; i++) {
          var surfaceNumber = productMenus[i].getAttribute('data-surfaces');
          var sel = selector[surfaceNumber - 1];
          console.log("selectorr", sel);

          var productMenu = $(productMenus[i]);
          if (sel.children == null) {
            var selectedProduct = productMenu.find('.product').removeClass('active').filter('[data-sel="' + sel.sel + '"]').addClass('active');
          }
          if (sel.children != null) {
            console.log("children", sel.children[0]);
            var selectedProduct = productMenu.find('.product').removeClass('active').filter('[data-sel="' + sel.children[0].sel + '"]').addClass('active');
          }
        }
      }
      controls.on('click', '#undo', function () {
        CHAM.VIZ.ChamViz({ action: 'undo' });
        highlightSelectedProduct();
      });

      controls.on('click', '#redo', function () {
        CHAM.VIZ.ChamViz({ action: 'redo' });
        highlightSelectedProduct();
      });

      controls.on('click', '#zoom', function () {
        var action = self.isZoomed ? 'unzoom' : 'zoom';
        CHAM.VIZ.ChamViz({ action: action });

        self.isZoomed = !self.isZoomed;
      });

      $('#print').on('click', 'img, span', function () {
        var specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
        var selector = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
        var url = '/print.aspx?specifier=' + encodeURIComponent(specifier)
          + (selector != null && selector !== '' 
            ? '&sel=' + encodeURIComponent(selector)
            : '');
        
        window.open(url);
      });

      controls.on('click', '#home', function () {
        CHAM.router.navigate('/');
      });
    }
  };
})(jQuery);