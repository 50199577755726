var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var getQueryParameter = Utilities.getQueryParameter;
  var isCladdingVersion = getQueryParameter('version') === 'cladding';

  CHAM.helpers = {
    getURLParameter: function getURLParameter(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)', 'i').exec(window.location.search.toLowerCase()) || [, ""])[1].replace(/\+/g, '%20')) || null;
    },
    isEmail: function isEmail(email) {
      return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(email);
    },
    createVisualizerUrl: function createVisualizerUrl(specifier, selector) {
      if (isCladdingVersion == true || specifier.indexOf('Exterior_11') > -1 || specifier.indexOf('Exterior_10') > -1) {
        console.log("selector", selector);
        return '?version=cladding#/scene/' + encodeURIComponent(specifier)
          + '/products/'
          + (selector != null && selector !== '' ? encodeURIComponent(selector) : '-1');
      }

      return '/#/scene/' + encodeURIComponent(specifier)
        + '/products/'
        + (selector != null && selector !== '' ? encodeURIComponent(selector) : '-1');
    },
    initShareLink: function initShareLink() {
      var fbLink = Utilities.getQueryParameter('fblink');
      if (fbLink == null || fbLink === '')
        return;
      
      var extracted = Utilities.parseShortenedLink(fbLink);
      var url = CHAM.helpers.createVisualizerUrl(extracted.specifier, extracted.selector);
      window.location = url;
    },
    getShortURL: function getShortURL(specifier, selector, callbackFn) {
      var url = CHAM.CurrentDomain + CHAM.helpers.createVisualizerUrl(specifier, selector);
      var data = { 'action': 'quick', 'url': url };
      var a = $.ajax({
        url: '/ChamWebTools/ShortUrlService.ashx',
        type: 'POST',
        data: data,
        dataType: 'text',
        async: true,
        statusCode: {
          400: function () {
          },
          500: function () {
            console.error('Action could not be completed.');
          }
        },
        success: function (newurl) {
          callbackFn.call(data, newurl);
        },
        fail: function () {
          console.error('Action could not be completed.');
        }
      });
    },
    convertCfxToCssColor: function (cfxColor) {
      var lowered = cfxColor.toLowerCase();

      return lowered.indexOf('color(') > -1
        ? lowered.trim().substring(6, lowered.length - 1)
        : null;
    },
    lazyLoad: function lazyLoad() {
      (function () {
        var w = window, de = document.documentElement, efp = function (x, y) { return document.elementFromPoint(x, y) };
        function isElementVisible(el) {
          var r = el.getBoundingClientRect();
          // Return false if it's not in the viewport
          if (r.right < 0 || r.bottom < 0 || r.left > (w.innerWidth || de.clientWidth) || r.top > (w.innerHeight || de.clientHeight)) return false;
          // Return true if any of its four corners are visible
          if (el.parentNode.tagName.toUpperCase() == 'LI') el = el.parentNode;
          return (el.contains(efp(r.left, r.top)) || el.contains(efp(r.right, r.top))
          || el.contains(efp(r.right, r.bottom)) || el.contains(efp(r.left, r.bottom)));
        };

        var raf = w.requestAnimationFrame || w.mozRequestAnimationFrame || w.webkitRequestAnimationFrame || w.msRequestAnimationFrame;
        var h = function (e) {
          e = e || window.event;
          var target = e.target || e.srcElement;
          var nodes = target.getElementsByTagName('img');
          for (var i = 0, n; n = nodes[i++];) (function (n) {
              var v = n.src;

              if (typeof v != 'undefined' && v != null && v != '') return;
              console.log('lazyt', n, v, isElementVisible(n));
            if (isElementVisible(n)) {
                console.log('isElementVisible', n);
              if (raf) raf(function () {
                n.src = n.getAttribute('data-src');
              });
              else n.src = n.getAttribute('data-src');
            }

          }(n));
        };

        if (w.addEventListener) {
          var attach = function (n) {
            var i, arr = ['DOMContentLoaded', 'load', 'scroll', 'resize'];
            while (i = arr.pop()) n.addEventListener(i, h, false);
          };
        } else if (w.attachEvent) {
          var attach = function (n) {
            var i, arr = ['onDOMContentLoaded', 'onload', 'onscroll', 'onresize'];
            while (i = arr.pop()) n.attachEvent(i, h);
          }
        }

        var nds = document.querySelectorAll('.lazy');
        for (var i = 0, n; n = nds[i++];) { attach(n); h({ target: n }); }

        w.LazyLoad = function (el, refresh) { if (!refresh) attach(el); h({ target: el }); }
      })();
    },
    polyfills: function polyfills() {

      Promise.prototype.thenReturn = function(value) {
        return this.then(function() {
          return value;
        });
      };

      if (!String.prototype.includes) {
        String.prototype.includes = function(search, start) {
          'use strict';
          if (typeof start !== 'number') {
            start = 0;
          }

          if (start + search.length > this.length) {
            return false;
          } else {
            return this.indexOf(search, start) !== -1;
          }
        };
      }

      if (typeof Object.assign != 'function') {
        (function () {
          Object.assign = function (target) {
            'use strict';
            // We must check against these specific cases.
            if (target === undefined || target === null) {
              throw new TypeError('Cannot convert undefined or null to object');
            }

            var output = Object(target);
            for (var index = 1; index < arguments.length; index++) {
              var source = arguments[index];
              if (source !== undefined && source !== null) {
                for (var nextKey in source) {
                  if (source.hasOwnProperty(nextKey)) {
                    output[nextKey] = source[nextKey];
                  }
                }
              }
            }
            return output;
          };
        })();
      }
    }

  };
})(jQuery);
