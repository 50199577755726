/* ==========================================================================
   My Projects
   ========================================================================== */
(function ($) {
	$.fn.extend({
		MyProjects: function (options) {
			var projects = {};

			// List the Options
			projects.listOptions = function listOptions(options) {
				var _opt = [];
				this.each(function () {
					_opt = jQuery.data(this, "options");
				});
				return _opt;
			};

			// List the Save Options
			projects.listSaveOptions = function listSaveOptions(options) {
				var _sOpt = [];
				this.each(function () {
					_sOpt = jQuery.data(this, "saveOptions");
				});
				return _sOpt;
			};

			projects.ClearAllCookies = function ClearAllCookies() {
				$.removeCookie('UserID');
				$.removeCookie('SiteID');
				$.removeCookie('email');
				$.removeCookie("UserID", { domain: '.chameleonpower.com', path: '/' });
				$.removeCookie("SiteID", { domain: '.chameleonpower.com', path: '/' });
				$.removeCookie("email", { domain: '.chameleonpower.com', path: '/' });
			};

			projects.checkCookieAuth = function checkCookieAuth(options) {
				var loginFail = false;
				var userIDCookieAvail = false;
				var siteIDCookieAvail = false;
				var emailCookieAvail = false;

				var storedOptions = $(this).data('options');
				userIDCookieAvail = ( $.cookie('UserID') != void 0) ? true : false;
				siteIDCookieAvail = ($.cookie('SiteID') != void 0) ? true : false;
				emailCookieAvail = ($.cookie('email') != void 0) ? true : false;

				if (userIDCookieAvail) {
					loginFail = true;
					if (!siteIDCookieAvail) loginFail = false;
					else {
						loginFail = true;
						if ($.cookie('SiteID') != storedOptions.siteid) loginFail = false;
					}
					if (!emailCookieAvail) loginFail = false;
				}
				else loginFail = false;

				if (!loginFail) {
				    projects['ClearAllCookies']();
				    //storedOptions.ModalContent.find('.projectsForm').html('');
				}
				$(this).data('loggedIn', loginFail);
		
				return loginFail;
			};

			// Run a Check and return true/false if logged in
			projects.checkLoggedIn = function checkLoggedIn(options) {
				var _isLoggedIn = [];

				this.each(function () {
					_isLoggedIn = jQuery.data(this, "loggedIn");
				});
				return _isLoggedIn;
			};

			// Perform the Logout process VIA processAjax
			projects.logout = function logout(options) {
			    this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					projects['processAjax'].call(storedOptions.ModalContainer, {
						sendData: {
							action: 'logout',
							SiteID: storedOptions.siteid
						}
					});
					projects['addSaveOptions'].call(storedOptions.ModalContainer, {
						specifier: '',
						sel: '',
						projectname: ''
					});
					storedOptions.ModalContent.find('div.projectsForm div.projectWrapper').html('');
					if (window.ChamStats != void 0) window.ChamStats.sendData('logout', options.email);
			    });
			};

			// Perform Login action via processAjax but first verify the form - // Pass the following: email, password
			projects.login = function login(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					if (storedOptions.IntegratedLogin || projects['ValidateForm'].call(storedOptions.ModalContainer, { form: '.loginForm' })) {
					    
						projects['processAjax'].call(storedOptions.ModalContainer, {
							sendData: {
								action: 'login',
								email: options.email,
								password: options.password,
								SiteID: storedOptions.siteid								
							}
						});
					}
				});
			};

			// Perform Register action via processAjax but first verify the form - // Pass the following if UseCustomRegistration is false: email: email, password: password, fName: fName, lName: lName, address: address
			projects.register = function register(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					if (projects['ValidateForm'].call(storedOptions.ModalContainer, { form: '.regForm' })) {
					    if (window.ChamStats != void 0) window.ChamStats.sendData('register', options.email);
					    if (!storedOptions.UseCustomRegistration) {					        
							jQuery.data(storedOptions.ModalContainer, 'returnMeTo', 'regform');
							projects['processAjax'].call(storedOptions.ModalContainer, {
								showMe: 'regform',
								sendData: {
									action: 'register',
									email: options.email,
									password: options.password,
									FName: options.fName,
									LName: options.lName,
									Address: options.address,
									SiteID: storedOptions.siteid
								}
							});

						}
						else console.warn('Custom Registration Setup has not been completed yet.'); // TODO: Create a function to handle Custom Registration
					}
				});
			};

			// Perform Forgot action via processAjax but first verify the form - // Pass the following: email: email
			projects.forgot = function forgot(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					if (projects['ValidateForm'].call(storedOptions.ModalContainer, { form: '.forgotForm' })) {
					    if (window.ChamStats != void 0) window.ChamStats.sendData('forgotPassword', '1');
						projects['processAjax'].call(storedOptions.ModalContainer, {
							showMe: 'forgotform',
							sendData: {
								action: 'forgot',
								email: options.email
							}
						});
					}
				});
			};

			// Process the Change Password Dialog via the processAjax Function after validating the form - // UserID, OldPassword, NewPassword, ConfirmPassword
			projects.changePassword = function changePassword(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var amILoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					var saveOptions = jQuery.data(this, 'saveOptions');
					var getUserID = ($.cookie('UserID') !== undefined) ? $.cookie('UserID') : jQuery.data(this, 'currentUserID');
					if (projects['ValidateForm'].call(storedOptions.ModalContainer, { form: '.changeForm' })) {
					    if (window.ChamStats != void 0) window.ChamStats.sendData('changePassword', '1');
						projects['processAjax'].call(storedOptions.ModalContainer, {
							sendData: {
								action: 'changepwd',
								UserID: getUserID,
								oldPWD: options.oldPassword,
								newPWD: options.newPassword
							}
						});

					}
				});
			};

			// Setup Ajax Calls to Server Side Code
			projects.processAjax = function processAjax(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var amILoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					var saveOptions = jQuery.data(this, 'saveOptions');
					var ReturnMeTo = jQuery.data(this, 'returnMeTo');
					var Redirect = jQuery.data(this, 'redirect');
					var SendToObj = this;
					var passed;
					/*if (saveOptions.specifier != '' && saveOptions.sel != '' && saveOptions.projectname != '') {
						passed = {
							SiteID: storedOptions.siteid,
							specifier: saveOptions.specifier,
							sel: saveOptions.sel,
							projectname: saveOptions.projectname,
							thumbsize: storedOptions.ThumbnailSize,
							UseCustom: storedOptions.UseCustomRegistration,
							guid: storedOptions.sessionId
							,aux1: saveOptions.aux1
						};
					}
					else {
						passed = {
							SiteID: storedOptions.siteid,
							thumbsize: storedOptions.ThumbnailSize,
							UseCustom: storedOptions.UseCustomRegistration,
							guid: storedOptions.sessionId
						};
					}*/

					if (saveOptions.specifier != '' && saveOptions.sel != '' && saveOptions.projectname != '') {
						passed = {
							SiteID: storedOptions.siteid,
							specifier: saveOptions.specifier,
							sel: saveOptions.sel,
							projectname: saveOptions.projectname,
							thumbsize: storedOptions.ThumbnailSize,
							UseCustom: storedOptions.UseCustomRegistration,
							guid: storedOptions.sessionId
							, aux1: saveOptions.aux1,
							aux2: saveOptions.aux2,
							useinteg: storedOptions.IntegratedLogin,
							useintegim: storedOptions.IntegratedIM
						};
					}
					else if (saveOptions.specifier != '' || saveOptions.sel != '') {
						passed = {
							SiteID: storedOptions.siteid,
							specifier: saveOptions.specifier,
							sel: saveOptions.sel,
							projectname: saveOptions.projectname,
							thumbsize: storedOptions.ThumbnailSize,
							UseCustom: storedOptions.UseCustomRegistration,
							guid: storedOptions.sessionId
							, aux1: saveOptions.aux1,
							aux2: saveOptions.aux2,
							useinteg: storedOptions.IntegratedLogin,
							useintegim: storedOptions.IntegratedIM
						};
					}
					else {
						passed = {
							SiteID: storedOptions.siteid,
							thumbsize: storedOptions.ThumbnailSize,
							UseCustom: storedOptions.UseCustomRegistration,
							guid: storedOptions.sessionId,
							useinteg: storedOptions.IntegratedLogin,
							useintegim: storedOptions.IntegratedIM
						};
					}
					var NewSendData = $.extend(options.sendData, passed);
					$.ajax({
						url: storedOptions.MyProjectsUtils,
						data: NewSendData,
						async: false,
                        method: 'POST',
						success: function (returnedData, status, settings) {
							var getLoginHeader = (settings.getResponseHeader('LoggedIn') == 'True') ? true : false;
							jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));

							options.srid = settings.getResponseHeader('SRID');
							jQuery.data(this, 'SRID', options.srid);

							/*if (options.action == 'login' && Redirect) {
								storedOptions.loginComplete.call(storedOptions.ModalContainer, Redirect);
								return;
							}*/

							if (options.action == 'login') {
							    if (window.ChamStats != void 0) window.ChamStats.sendData('login', options.email);
							}
							if (typeof (Redirect) === "function") {
								Redirect.call(storedOptions.ModalContainer);
								return;
							}

							if (typeof Redirect != 'undefined') {
							    window.location = Redirect;
							    return;
							}
							if (typeof ReturnMeTo != 'undefined') {
								if (!getLoginHeader) options.showMe = 'loginform';
								else options.showMe = 'projectsform';
							}
							else {
								options.showMe = ReturnMeTo;
							}
							if (getLoginHeader) {
							    //storedOptions.ModalContent.find('.projectsForm').html(returnedData);
							    storedOptions.ModalContent.find('div.projectsForm').html(returnedData);


							}
							if (options.action != 'remove') projects['showHideContainers'].call(storedOptions.ModalContainer, options);

							if ((NewSendData.action == 'list' || NewSendData.action == 'login') && NewSendData.specifier != '' && NewSendData.specifier != void 0) {
							    storedOptions.saveComplete.call(storedOptions.ModalContainer, NewSendData);
							}


						},
						error: function (request, ajaxOptions, thrownError) {
							var getErrorHeader = request.getResponseHeader('Error');
							var getChangeHeader = (request.getResponseHeader('ChangeRequired') == 'True') ? true : false;
							var getCurrentUserID = request.getResponseHeader('ChangeUserID');

							if (!getChangeHeader) {
								var getLoginHeader = (request.getResponseHeader('LoggedIn') == 'True') ? true : false;
								jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
								if (!getLoginHeader) options.showMe = 'loginform';
								else if (getLoginHeader) options.showMe = 'projectsform';
								else options.showMe = options.showMe;
								projects['showHideContainers'].call(storedOptions.ModalContainer, options);
								$.each(storedOptions.forms, function (i, form) {
								    //console.log(form);
									if (form.is(':visible')) {
										form.find('div.errors').html(getErrorHeader);
										projects['CheckForMessages'].call(storedOptions.ModalContainer, { form: form });
									}
								});
							}
							else {
								jQuery.data(storedOptions.ModalContainer, 'currentUserID', getCurrentUserID);
								options.changeUserID = getCurrentUserID;
								options.showMe = 'changeform';
								projects['showHideContainers'].call(storedOptions.ModalContainer, options);
							}
						}
					});
					projects['addSaveOptions'].call(storedOptions.ModalContainer, {
						specifier: '',
						sel: '',
						projectname: ''
					});
				});
			};

			// Open Modal
			projects.open = function open(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var amILoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					if (amILoggedIn && typeof options.redirect != 'undefined') {
					    window.location = options.redirect;
					    return false;
					}

					storedOptions.ModalContainer.show('fade');

					if (!options.showMe) {
						if (!amILoggedIn) options.showMe = 'loginform';
						else if (amILoggedIn) options.showMe = 'projectsform';
					}

					if (typeof options.redirect != 'undefined') {
					    jQuery.data(this, 'redirect', options.redirect);
					}

					projects['showHideContainers'].call(storedOptions.ModalContainer, options);
					
					$('html, body').off('scroll touchmove mousewheel').on('scroll touchmove mousewheel', function (e) {
						if (($(e.target).parents('.MyProjectsInner ').length === 0) && (storedOptions.ModalInner.has(e.target).length === 0) && (storedOptions.ModalInner[0] !== e.target) && (storedOptions.ModalContainer.is(':visible'))) {
							e.preventDefault();
							e.stopPropagation();
							return false;
						}
					}).css({
						'overflow': 'hidden'
					});

				});
			};

			// Close the Modal
			projects.close = function close(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var amILoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));

					if (storedOptions.RequireLogin) {
						if (amILoggedIn) storedOptions.ModalContainer.hide('fade');
					}
					else if (!storedOptions.RequireLogin) {
						storedOptions.ModalContainer.hide('fade');
					}

					$('html, body').removeAttr('style').off('scroll touchmove mousewheel');

					projects['addSaveOptions'].call(storedOptions.ModalContainer, {
						specifier: '',
						sel: '',
						projectname: ''
					});
				});
			};

			// Hide Containers other then what needs to be - // perform test to see if logged in if so then show the content based on Options.showMe
			projects.showHideContainers = function showHideContainers(options) {
				this.each(function () {
					if (options.changeUserID) {
						jQuery.data(this, 'currentUserID', parseInt(options.changeUserID));
					}
					var storedOptions = jQuery.data(this, 'options');
					var LoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					$.each(storedOptions.forms, function (i, form) {
					    $(form).not(storedOptions.forms[options.showMe]).hide();					    
					    $(storedOptions.forms[options.showMe]).show();
					    if (options.showMe.indexOf('regform') > -1) {

					        $(storedOptions.forms[options.showMe]).parent().show();
					    }
						$(storedOptions.forms[options.showMe]).find('div.errors').html('&nbsp;').hide();
						$(storedOptions.forms[options.showMe]).find('div.success').html('&nbsp;').hide();
						if ($(storedOptions.forms[options.showMe]).is(':visible') && options.showMe == 'projectsform') {
							$(storedOptions.forms[options.showMe]).find('div.projectWrapper:last').css('margin-bottom', 0);
						}
					});
					
					if (storedOptions.RequireLogin) {
						if (!LoggedIn) storedOptions.ModalContainer.find('.titleBarBTNS .buttons').fadeOut();
						else storedOptions.ModalContainer.find('.titleBarBTNS .buttons').fadeIn();
					}
					else if (!storedOptions.RequireLogin) {
						if (!LoggedIn) {
							storedOptions.ModalContainer.find('.titleBarBTNS .logoutBTN').fadeOut();
							storedOptions.ModalContainer.find('.titleBarBTNS .goToChangeBTN').fadeOut();
						}
						else storedOptions.ModalContainer.find('.titleBarBTNS .buttons').fadeIn();
					}
					
					projects['CheckVisible'].call(storedOptions.ModalContainer);

				});
			};

			projects.CheckVisible = function CheckVisible(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var LoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					$.each(storedOptions.forms, function (i, form) {
						if (form.is(':visible')) {
							projects['clicks'].call(storedOptions.ModalContainer, {
								clickType: i
							});
						}
					});
					storedOptions.ModalContainer.find('div.titleBarBTNS div.closeBTN').off('click').on('click', function (e) {
						projects['close'].call(storedOptions.ModalContainer);
					});

					storedOptions.ModalContainer.off('click').on('click', function (e) {
						if (($(e.target).parents('.MyProjectsInner ').length === 0) && (storedOptions.ModalInner.has(e.target).length === 0) && (storedOptions.ModalInner[0] !== e.target) && (storedOptions.ModalContainer.is(':visible'))) {
							if (!$(e.target).hasClass('Remove')) projects['close'].call(storedOptions.ModalContainer);
						}
					});
					storedOptions.ModalContainer.find('div.titleBarBTNS div.logoutBTN').off('click').on('click', function (e)
					{
						projects['logout'].call(storedOptions.ModalContainer);
					});

					storedOptions.ModalContainer.find('div.titleBarBTNS div.goToChangeBTN').off('click').on('click', function (e) {
						projects['showHideContainers'].call(storedOptions.ModalContainer, {
							showMe: 'changeform'
						});
					});
				});
			};

			// Show the Save Dialog
			projects.showSave = function showSave(options) { // TODO: get it to require login first before being able to enter a saved project name, once logged in return back to the save dialog.
				this.each(function () {

					var storedOptions = jQuery.data(this, 'options');
					var saveOptions = jQuery.data(this, 'saveOptions');
					var amILoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					var newSaveOptions = saveOptions;

					if (options.projectname) $.extend(newSaveOptions, { projectname: options.projectname });
					if (options.sel) $.extend(newSaveOptions, { sel: options.sel });
					var spec = storedOptions.spec;

					if (options.specifier) spec = options.specifier;
					else if (options.spec) spec = options.spec;

					$.extend(newSaveOptions, { specifier: spec });
					if (options.aux1) $.extend(newSaveOptions, { aux1: options.aux1 });
					if (options.aux2) $.extend(newSaveOptions, { aux2: options.aux2 });

					jQuery.data(this, 'saveOptions', newSaveOptions);

					var ReturnMeTo = jQuery.data(this, 'returnMeTo');

					jQuery.data(this, 'returnMeTo', 'showSave');
					if (window.ChamStats != void 0) window.ChamStats.sendData('showSave', '1');

					projects['open'].call(storedOptions.ModalContainer, { showMe: 'saveform' });
				});
			};

			// Process the Save Dialog via the processAjax function after validating the form - // Project Name, specifier, sel
			projects.save = function save(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var curSavedOpt = jQuery.data(this, 'saveOptions');
					var loggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));

					if (projects['ValidateForm'].call(storedOptions.ModalContainer, { form: '.saveForm' }) || options.autosave) {
						var newSaveOptions = curSavedOpt;

						if (options.projectname) $.extend(newSaveOptions, { projectname: options.projectname });
						if (options.sel) $.extend(newSaveOptions, { sel: options.sel });
						var spec = curSavedOpt.specifier;				

						if (options.specifier) spec = options.specifier;
						else if (options.spec) spec = options.spec;

					    if (window.ChamStats != void 0) window.ChamStats.sendData('save', 'spec=' + spec + '&sel='  + newSaveOptions.sel);

						$.extend(newSaveOptions, { specifier: spec });
						if (options.aux1) $.extend(newSaveOptions, { aux1: options.aux1 });
						if (options.aux2) $.extend(newSaveOptions, { aux2: options.aux2 });

						jQuery.data(this, 'saveOptions', newSaveOptions);
						/*jQuery.data(this, 'saveOptions', {
							specifier: curSavedOpt.spec,
							sel: curSavedOpt.sel,
							projectname: options.projectname
						});*/

						if (!loggedIn) {
							options.showMe = 'loginform';
						}
						else {
							options.showMe = 'projectsform';
							projects['processAjax'].call(storedOptions.ModalContainer, {
								sendData: {
									action: 'list'
								}
							});
						}

						//storedOptions.saveComplete.call(storedOptions.ModalContainer);
						projects['showHideContainers'].call(storedOptions.ModalContainer, options);
						projects['CheckForMessages'].call(storedOptions.ModalContainer, { form: '.saveForm' });

					}
				});
			};

			// Show Modal - was not used but could be used.
			projects.showModal = function showModal(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var amILoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					var saveOptions = jQuery.data(this, 'saveOptions');
					var ReturnMeTo = jQuery.data(this, 'returnMeTo');

					storedOptions.ModalContainer.fadeIn();

					$('html, body').off('scroll touchmove mousewheel').on('scroll touchmove mousewheel', function (e) {
						if (($(e.target).parents('.MyProjectsInner ').length === 0) && (storedOptions.ModalInner.has(e.target).length === 0) && (storedOptions.ModalInner[0] !== e.target) && (storedOptions.ModalContainer.is(':visible'))) {
							e.preventDefault();
							e.stopPropagation();
							return false;
						}
					}).css({
						'overflow': 'hidden'
					});
				});
			};

			projects.clicks = function clicks(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var amILoggedIn = jQuery.data(this, 'loggedIn', projects['checkCookieAuth'].call(storedOptions.ModalContainer));
					var saveOptions = jQuery.data(this, 'saveOptions');
					var CurrentUserID = (jQuery.data(this, 'currentUserID') != 0) ? jQuery.data(this, 'currentUserID') : $.cookie('UserID');
					var clickables = {};
					var hdnSaveSpec = saveOptions.specifier, hdnSaveSel = saveOptions.sel, hdnSaveName = saveOptions.projectname;
					clickables.changeform = function loginform(options) { // all of the clicks for the change password form

						var oldPWD = $(document.getElementById('OldPassword'));
						var newPWD = $(document.getElementById('NewPassword'));
						var newConfirmPWD = $(document.getElementById('ConfirmNewPassword'));

						storedOptions.ModalContainer.find(oldPWD).off('keydown').on('keydown', function (e) {
							if (e.which == 13) {
								projects['changePassword'].call(storedOptions.ModalContainer, {
									UserID: CurrentUserID,
									oldPassword: oldPWD.val(),
									newPassword: newPWD.val(),
									newConfirmPassword: newConfirmPWD.val()
								});
								oldPWD.val('')
								newPWD.val('')
								newConfirmPWD.val('');
							}
						});

						storedOptions.ModalContainer.find(newPWD).off('keydown').on('keydown', function (e) {
							if (e.which == 13) {
								projects['changePassword'].call(storedOptions.ModalContainer, {
									UserID: CurrentUserID,
									oldPassword: oldPWD.val(),
									newPassword: newPWD.val(),
									newConfirmPassword: newConfirmPWD.val()
								});
								oldPWD.val('')
								newPWD.val('')
								newConfirmPWD.val('');
							}
						});

						storedOptions.ModalContainer.find(newConfirmPWD).off('keydown').on('keydown', function (e) {
							if (e.which == 13) {
								projects['changePassword'].call(storedOptions.ModalContainer, {
									UserID: CurrentUserID,
									oldPassword: oldPWD.val(),
									newPassword: newPWD.val(),
									newConfirmPassword: newConfirmPWD.val()
								});
								oldPWD.val('')
								newPWD.val('')
								newConfirmPWD.val('');
							}
						});

						storedOptions.ModalContainer.find('div.changePwdBTN').off('click').on('click', function (e) {

							projects['changePassword'].call(storedOptions.ModalContainer, {
								UserID: CurrentUserID,
								oldPassword: oldPWD.val(),
								newPassword: newPWD.val(),
								newConfirmPassword: newConfirmPWD.val()
							});
							oldPWD.val('')
							newPWD.val('')
							sword: newConfirmPWD.val('');

						});
					};
					clickables.loginform = function loginform(options) { // all of the clicks for the login form		
						$("input[type='text']:visible:enabled:first").focus();
						var EmailField = $(document.getElementById('loginEmail'));
						var PasswordField = $(document.getElementById('loginPassword'));

						storedOptions.ModalContainer.find(PasswordField).off('keydown').on('keydown', function (e) {
							if (e.which == 13) {
								var email = EmailField.val();
								var password = PasswordField.val();
								var loginOptions;
								if (hdnSaveSpec != '' && hdnSaveSel != '' && hdnSaveName != '') {
									loginOptions = {
										email: email,
										password: password,
										specifier: hdnSaveSpec,
										sel: hdnSaveSel,
										projectname: hdnSaveName
									};
								}
								else {
									loginOptions = {
										email: email,
										password: password
									};
								}

								projects['login'].call(storedOptions.ModalContainer, loginOptions);
								EmailField.val('');
								PasswordField.val('');
							}
						});

						storedOptions.ModalContainer.find('div.loginBTN').off('click').on('click', function (e) {
							var email = EmailField.val();
							var password = PasswordField.val();
							var loginOptions;
							if (hdnSaveSpec != '' && hdnSaveSel != '' && hdnSaveName != '') {
								loginOptions = {
									email: email,
									password: password,
									specifier: hdnSaveSpec,
									sel: hdnSaveSel,
									projectname: hdnSaveName
								};
							}
							else {
								loginOptions = {
									email: email,
									password: password,
									thumbsize: storedOptions.ThumbnailSize
								};
							}

							projects['login'].call(storedOptions.ModalContainer, loginOptions);
							EmailField.val('');
							PasswordField.val('');
						});

						storedOptions.ModalContainer.find('div.gotoForgotBTN').off('click').on('click', function (e) {
							projects['showHideContainers'].call(storedOptions.ModalContainer, { showMe: 'forgotform' });
						});
						
						storedOptions.ModalContainer.find('div.gotoRegBTN').off('click').on('click', function (e) {
							projects['showHideContainers'].call(storedOptions.ModalContainer, { showMe: 'regform' });
                        });

                        storedOptions.ModalContainer.find('div.titleBarBTNS div.closeBTN').off('click').on('click', function (e) {
                            projects['showHideContainers'].call(storedOptions.ModalContainer);
                        });
					    //eacomment: this line was added to avoid focus still being set on an input box behind the modal which was causing mobile scroll issues
                        if ($("#my-projects input[type='text']").length > 0) $("#my-projects input[type='text']").get(0).focus();
					};

					clickables.regform = function regform() { // all of the clicks for the Registration form
					    storedOptions.ModalContent.find('div.projectsForm div.projectWrapper').html('');
						$("input[type='text']:visible:enabled:first").focus();
						var hdnSaveSpec = saveOptions.specifier;
						var hdnSaveSel = saveOptions.sel;
						var hdnSaveName = saveOptions.projectname;

						var RegEmailField = $(document.getElementById('RegEmail'));
						var RegPasswordField = $(document.getElementById('RegPassword'));
						var RegFNameField = $(document.getElementById('RegFName'));
						var RegLNameField = $(document.getElementById('RegLName'));
						var RegAddressField = $(document.getElementById('RegAddress'));

						storedOptions.ModalContainer.find('div.regBTN').off('click').on('click', function (e) {
							var email = RegEmailField.val();
							var password = RegPasswordField.val();
							var fName = RegFNameField.val();
							var lName = RegLNameField.val();
							var address = RegAddressField.val();
                            
							projects['register'].call(storedOptions.ModalContainer, { email: email, password: password, fName: fName, lName: lName, address: address });

							RegEmailField.val('');
							RegPasswordField.val('');
							RegFNameField.val('');
							RegLNameField.val('');
							RegAddressField.val('');
						});

						storedOptions.ModalContainer.find('div.gotoLoginBTN').off('click').on('click', function (e) {
							projects['showHideContainers'].call(storedOptions.ModalContainer, { showMe: 'loginform' });
                        });

                        storedOptions.ModalContainer.find('div.titleBarBTNS div.closeBTN').off('click').on('click', function (e) {
                            projects['showHideContainers'].call(storedOptions.ModalContainer);
                        });

						storedOptions.ModalContainer.find('div.gotoForgotBTN').off('click').on('click', function (e) {
							projects['showHideContainers'].call(storedOptions.ModalContainer, { showMe: 'forgotform' });
						});

					};

					clickables.forgotform = function forgotform() { // all of the clicks for the Forgot form
						$("input[type='text']:visible:enabled:first").focus();
						var forgotFormField = $(document.getElementById('txtForgotEmail'));
						storedOptions.ModalContainer.find('div.forgotBTN').off('click').on('click', function (e) {
							var forgotEmail = forgotFormField.val();

							projects['forgot'].call(storedOptions.ModalContainer, {
								email: forgotEmail
							});
							forgotFormField.val('');
						});

						storedOptions.ModalContainer.find(forgotFormField).off('keydown').on('keydown', function (e) {
							if (e.which == 13) {
								var forgotEmail = $(this).val();
								projects['forgot'].call(storedOptions.ModalContainer, {
									email: forgotEmail
								});
								$(this).val('');
							}
						});

						storedOptions.ModalContainer.find('div.gotoLoginBTN').off('click').on('click', function (e) {
							projects['showHideContainers'].call(storedOptions.ModalContainer, { showMe: 'loginform' });
						});
						
						storedOptions.ModalContainer.find('div.gotoRegBTN').off('click').on('click', function (e) {
							projects['showHideContainers'].call(storedOptions.ModalContainer, { showMe: 'regform' });
						});
					};

					clickables.saveform = function saveform() { // all of the clicks for the Save form
						$("input[type='text']:visible:enabled:first").focus();

						var spec = storedOptions.spec;
						var sel = saveOptions.sel;
						var projectNameField = $(document.getElementById('SaveProjectName'));

						storedOptions.ModalContainer.find(projectNameField).off('keydown').on('keydown', function (e) {
							if (e.which == 13) {
								var ProjectName = jQuery.trim($(this).val());
								projects['save'].call(storedOptions.ModalContainer, {
									specifier: spec,
									sel: sel,
									projectname: ProjectName
								});
								$(this).val('');
							}
						});

						storedOptions.ModalContainer.find('div.saveBTN').off('click').on('click', function (e) {
							var ProjectName = jQuery.trim(projectNameField.val());

							projects['save'].call(storedOptions.ModalContainer, {
								specifier: spec,
								sel: sel,
								projectname: ProjectName
							});
							projectNameField.val('');
						});

					};

					clickables.projectsform = function projectsform() { // all of the clicks for the Projects Form					
						projects['addSaveOptions'].call(storedOptions.ModalContainer, {
							specifier: '',
							sel: '',
							projectname: ''
						});

						storedOptions.ModalContainer.find('div.projectWrapper:last').css('margin-bottom', 0);

						storedOptions.ModalContainer.find('img.savedRender').each(function () {
							var savedSrc = $(this).attr('src');
							var correctedSRC = savedSrc.replace('Size=128', 'Size=' + storedOptions.ThumbnailSize);
							$(this).css('width', storedOptions.ThumbnailSize).attr('src', correctedSRC);
						});

						storedOptions.ModalContainer.find('li.Use').off('click').on('click', function (e) {
							var spec = $(this).attr('data-spec');
							var sel = $(this).attr('data-sel');
							var srid = $(this).attr('data-srid');
							var imid = $(this).attr('data-imid');
							var imid3 = $(this).attr('data-imid3');
							var action = $(this).attr('data-action');
							var Aux1 = $(this).attr('data-aux1');
							var Aux2 = $(this).attr('data-aux2');
							var sendUse = {
								spec: spec,
								sel: sel
							};
							if (srid != '0') $.extend(sendUse, { SRID: srid });
							if (imid != '0') $.extend(sendUse, { IMID: imid });
							if (imid3 != '0') $.extend(sendUse, { IMID3: imid3 });
							if (Aux1) $.extend(sendUse, { Aux1: Aux1 });
							if (Aux2) $.extend(sendUse, { Aux2: Aux2 });
							sendUse.action = action;
							projects['use'].call(storedOptions.ModalContainer, sendUse);
						});

						storedOptions.ModalContainer.find('li.Remove').off('click').on('click', function (e) {
							/*var srid = $(this).data('srid');
							var imid = $(this).data('imid');
							var imid3 = $(this).data('imid3');*/
							var srid = $(this).attr('data-srid');
							var imid = $(this).attr('data-imid');
							var imid3 = $(this).attr('data-imid3');
							var spec = $(this).attr('data-spec');
							var action = $(this).attr('data-action');
							return projects['remove'].call(storedOptions.ModalContainer, {
								SRID: srid,
								IMID: imid,
								IMID3: imid3,
								action: action,
                                spec: spec
							});
						});
					};

					if (options.clickType && $.isFunction(clickables[options.clickType])) {
						return clickables[options.clickType].call(storedOptions.ModalContainer, options);
					}
					else return false;
				});
			};

			// Perform Remove Project via ProcessAjax function - // sends back to RemoveProjectHandler call back, pass to this the following: SRID
			projects.remove = function remove(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					
					/*var SRID = options.SRID || 0;
					var IMID = options.IMID || 0;
					var IMID3 = options.IMID3 || 0;
					var sendingData = {
						action: 'remove',
						SRID: options.SRID,
						IMID: options.IMID,
						IMID3: options.IMID3
					};*/

					var sendingData = { action: 'remove' };
					if (options.SRID) $.extend(sendingData, { SRID: options.SRID });
					if (options.IMID) $.extend(sendingData, { IMID: options.IMID });
					if (options.IMID3) $.extend(sendingData, { IMID3: options.IMID3 });
					if (options.custom) $.extend(sendingData, { customUI: options.custom });

					projects['processAjax'].call(storedOptions.ModalContainer, {
						sendData: sendingData
					});

					storedOptions.RemoveProjectHandler.call(storedOptions.ModalContainer, options.SRID, options.IMID, options.IMID3, options.action, options.spec);
				});
			};

			// Perform the Use Project handler - // Pass back the Specifier and Sel from the button that's clicked
			projects.use = function use(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					var SRID = options.SRID || 0;
					var IMID = options.IMID || 0;
					var IMID3 = options.IMID3 || 0;
					var Aux1 = options.Aux1 || "";
					var Aux2 = options.Aux2 || "";
					storedOptions.UseProjectHandler.call(storedOptions.ModalContainer, options.spec, options.sel, SRID, IMID, IMID3, options.action, Aux1, Aux2);
					//projects['close'].call(storedOptions.ModalContainer);
				});
			};

			// Add or Remove Save Options by passing // specifier, sel, projectname
			projects.addSaveOptions = function addSaveOptions(options) {
				return this.each(function () {
					var newDefaults = {
						specifier: options.specifier,
						sel: options.sel,
						projectname: options.projectname
					};
					var currentSaveOptions = jQuery.data(this, 'saveOptions');
					var newSaveOptions = $.extend(currentSaveOptions, newDefaults);
					jQuery(this, 'saveOptions', newSaveOptions);
				});
			};

			// Validating of Forms Checker - // Requires you to pass the class to search for fields.
			projects.ValidateForm = function ValidateForm(options) { // form
				var formValid = true;
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');

					var ValidateField = function ValidateField($field) {
						$field.val(jQuery.trim($field.val()));
						if (!$field.is(':visible')) return true;
						var attr = $field.attr('data-validation');

						if (typeof attr == 'undefined') $field.attr('data-validation', '');
						var attr = $field.attr('data-validation');
						var fieldVal = jQuery.trim($field.val());


						if (attr.indexOf('required') > -1) {
							if (fieldVal == '') return false;
						}
						if (attr.indexOf('oldnewpwd') > -1) {
							var isSameAsNew = $(document.getElementById($field.attr('data-new')));
							if (fieldVal == isSameAsNew.val()) return false;
							else return true;
						}
						if (attr.indexOf('confirm') > -1) {
							var confirmWith = $(document.getElementById($field.attr('data-confirm-with')));
							if (fieldVal != confirmWith.val()) return false;
							else return true;
						}

						if (fieldVal != '') {
							if (attr.indexOf('email') > -1) {
								var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
								var bOK = true;

								// if multiple emails, split and check each
								var sfield = fieldVal.replace(',', ';');
								var sflds = sfield.split(';');

								for (var i = 0; i < sflds.length; i++) {
									sfield = sflds[i];
									if (!re.test(sfield)) {
										bOK = false;
										break;
									}
								}

								return bOK
							}
							if (attr.indexOf('numeric') > -1) {
								var n = fieldVal;
								return !isNaN(parseFloat(n)) && isFinite(n);
							}
							if (attr.indexOf('ProjectName') > -1) {
								var n = fieldVal;
								if (n == "Untitled Project") return false;
								else return true;
							}
						}
						return true;
					};
					var $form = storedOptions.ModalContainer.find(options.form);
					$form.find('div.errors').html('&nbsp;');
					var arr = $form.find(':input').toArray();
					var errorMSG = [];
					for (var i = 0; i < arr.length; i++) {
						var $i = $(arr[i]);
						if (!ValidateField($i)) {
							errorMSG.push($i.attr('data-validation-msg'));
							formValid = false;
						}
					}

					if (!formValid) {
						$form.find('div.errors').html(errorMSG.join("<br />"));
					}
					projects['CheckForMessages'].call(storedOptions.ModalContainer, { form: $form });
				});
				return formValid;
			};

			// Check to see if there are any messages in the errors or success divs
			projects.CheckForMessages = function CheckForMessages(options) {
				this.each(function () {
					var storedOptions = jQuery.data(this, 'options');
					if (storedOptions.ModalContent.find(options.form).find('div.success').html() != "&nbsp;") {
						storedOptions.ModalContent.find('div.success').show().fadeOut(2000, function () {
							$(this).html('&nbsp;');
						});
					}
					else {
						storedOptions.ModalContent.find(options.form).find('div.success').hide();
					}
					if (storedOptions.ModalContent.find(options.form).find('div.errors').html() == "&nbsp;") {
						storedOptions.ModalContent.find(options.form).find('div.errors').hide();
					}
					else {
						storedOptions.ModalContent.find(options.form).find('div.errors').show();
					}
				});
			};

			// Generates a GUID
			projects.createGUID = function () {
				return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16);
				});
			};

			// Check if this is a API Action Request
			if (options.action) {
				if ($.isFunction(projects[options.action])) {
					return projects[options.action].call(this, options);
				}
				else return console.warn('The ' + options.action + ' API Action Request does not exist');
			}

			var defaults = {
				'ModalTitle': 'My Visualizations',
				'ModalContainer': undefined,
				'ModalInner': undefined,
				'ModalContent': undefined,
				'VizContainer': undefined,
				'MyProjectsHTML': '/ChamWebTools/MyProjects/1/MyProjectsHTML.ashx',
				'MyProjectsUtils': '/ChamWebTools/MyProjects/1/MyProjectsUtils.ashx',
				'MyProjectsBaseCSS': '/ChamWebTools/MyProjects/1/MyProjects.css',
				'forms': {
					'loginform': 'loginForm',
					'regform': 'regForm',
					'forgotform': 'forgotForm',
					'saveform': 'saveForm',
					'projectsform': 'projectsForm',
					'changeform': 'changeForm'
				},
				'siteid': '',
				'spec': '',
				'modalWidth': 590,
				'modalHeight': 530,
				'ThumbnailSize': 128,
				'UseCustomRegistration': false,
				'RequireLogin': false,
				'Draggable': false,
				'IntegratedLogin': false,
				'IntegratedIM': false,
				'UseProjectHandler': function (spec, sel, SRID, IMID, IMID3, Action, Aux1, Aux2) { }, // use this to integrate directly and run the function that will reload your visualizer.
				'RemoveProjectHandler': function (SRID, IMID, IMID3, action, spec) { }, // use this to integrate directly and run the function that will reload your visualizer.
				'saveComplete': function (data) { },   //passes: SiteID,UseCustom,action,guid,projectname,sel,specifier,thumbsize,useinteg,useintegim
				'loginComplete': function (redirect) { }
			};
			var saveDefaults = {
				specifier: '',
				sel: '',
				projectname: ''
			};
			// Create a Session ID
			options.sessionId = projects.createGUID();

			var options = $.extend(defaults, options);
			var saveOptions = $.extend(saveDefaults, {
				specifier: '',
				sel: '',
				projectname: ''
			});

			

			var _getProjectHTML = function _getProjectHTML(options) { //options.ModalContent, options.MyProjectsHTML, options.UseCustomRegistration, options.siteid, options.ThumbnailSize, options.sessionId	

				$.ajax({
					url: options.MyProjectsHTML,
					async: false,
					data: {
						SiteID: options.siteid,
						UseCustom: options.UseCustomRegistration,
						thumbsize: options.ThumbnailSize,
						useintegim: options.IntegratedIM,
						guid: options.sessionId
					},
					success: function (response) {
						options.ModalContent.html(response);

						$.extend(true, options.forms, {
							loginform: options.ModalContent.find('.loginForm'),
							regform: options.ModalContent.find('.regForm'),
							forgotform: options.ModalContent.find('.forgotForm'),
							saveform: options.ModalContent.find('.saveForm'),
							projectsform: options.ModalContent.find('.projectsForm'),
							changeform: options.ModalContent.find('.changeForm')
						});

					}
				});
			};

			var _LoggedInCheck = function _LoggedInCheck() {
				var loggedIn = projects['checkCookieAuth'].call(options.ModalContainer);
				/*if ($.cookie('UserID') !== undefined && $.cookie('SiteID') !== undefined && $.cookie('email') !== undefined) {
					loggedIn = true;
				}*/
				return loggedIn;
			};

			return this.each(function () {
				options.ModalContainer = $(this).addClass('MyProjects').hide();
				options.ModalContainer.empty();
				options.ModalInner = $('<div/>').addClass('MyProjectsInner').css({ 'width': options.modalWidth }).appendTo($(this));
				if ($('head').find('link.myProjectsBase').length > 0) $('head').find('link.myProjectsBase').remove();
				$('head').append('<link rel="stylesheet" href="' + options.MyProjectsBaseCSS + '?v' + options.sessionId + '" class="myProjectsBase" />');


				// Setup the Title Bar Section
				var buildTitleBar = [];
				buildTitleBar.push('<h2>' + options.ModalTitle + '</h2>');
				buildTitleBar.push('<div class="titleBarBTNS">');
				if (!options.IntegratedLogin) buildTitleBar.push('<div class="buttons goToChangeBTN">Change Password?</div>');
				if (!options.IntegratedLogin) buildTitleBar.push('<div class="buttons logoutBTN">Log Out</div>');
				buildTitleBar.push('<div class="buttons closeBTN logged">&times;</div>');
				buildTitleBar.push('</div>');
				$('<div/>').addClass('TitleBar').html(buildTitleBar.join('')).appendTo(options.ModalInner);


				// Setup the Content Section.
				options.ModalContent = $('<div/>').addClass('Content').appendTo(options.ModalInner);

				// Setup Draggability if set to true
				if (options.Draggable) {
					options.ModalInner.addClass('ui-widget-content').addClass('Draggable').draggable({ cursor: "url(/ChamWebTools/MyProjects/1/grabbing.png) 8 8, move", handle: ".TitleBar h2", containment: options.ModalContainer });
				}

				_getProjectHTML(options);

				jQuery.data(this, 'options', options);
				jQuery.data(this, 'loggedIn', _LoggedInCheck());
				jQuery.data(this, 'saveOptions', saveOptions);
				jQuery.data(this, 'returnMeTo', 'undefined');
				jQuery.data(this, 'currentUserID', 0);

				if (options.RequireLogin) {
					var loggedIn = projects['checkCookieAuth'].call(options.ModalContainer);
					if (!loggedIn) {
						projects['open'].call(options.ModalContainer, { showMe: 'loginform' });
					}
				}


				if (window.ChamStats == void 0) {
				    var scrpt = document.createElement('script');
				    scrpt.src = '//ca.chameleonpower.com/stats.js';
				    document.head.appendChild(scrpt);
				}


			});


		}
	});
})(jQuery);

/* ==========================================================================
   Jquery Cookie Plugin Minified
   ========================================================================== */
(function (e) { if (typeof define === "function" && define.amd) { define(["jquery"], e) } else { e(jQuery) } })(function (e) { function n(e) { return u.raw ? e : encodeURIComponent(e) } function r(e) { return u.raw ? e : decodeURIComponent(e) } function i(e) { return n(u.json ? JSON.stringify(e) : String(e)) } function s(e) { if (e.indexOf('"') === 0) { e = e.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, "\\") } try { e = decodeURIComponent(e.replace(t, " ")) } catch (n) { return } try { return u.json ? JSON.parse(e) : e } catch (n) { } } function o(t, n) { var r = u.raw ? t : s(t); return e.isFunction(n) ? n(r) : r } var t = /\+/g; var u = e.cookie = function (t, s, a) { if (s !== undefined && !e.isFunction(s)) { a = e.extend({}, u.defaults, a); if (typeof a.expires === "number") { var f = a.expires, l = a.expires = new Date; l.setDate(l.getDate() + f) } return document.cookie = [n(t), "=", i(s), a.expires ? "; expires=" + a.expires.toUTCString() : "", a.path ? "; path=" + a.path : "", a.domain ? "; domain=" + a.domain : "", a.secure ? "; secure" : ""].join("") } var c = t ? undefined : {}; var h = document.cookie ? document.cookie.split("; ") : []; for (var p = 0, d = h.length; p < d; p++) { var v = h[p].split("="); var m = r(v.shift()); var g = v.join("="); if (t && t === m) { c = o(g, s); break } if (!t && (g = o(g)) !== undefined) { c[m] = g } } return c }; u.defaults = {}; e.removeCookie = function (t, n) { if (e.cookie(t) !== undefined) { e.cookie(t, "", e.extend({}, n, { expires: -1 })); return true } return false } })

