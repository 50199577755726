var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var getQueryParameter = Utilities.getQueryParameter;
  var isCladdingVersion = getQueryParameter('version') === 'cladding';

  function isIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  var quoteSuccess = isCladdingVersion
    ? 'https://www.fiberondecking.com/cladding-for-architects/exterior-cladding-visualizer/confirmation'
    : 'https://www.fiberondecking.com/start-your-project/get-a-quote/get-a-quote-confirmation';

  CHAM.quote = {
    init: function () {
      this.container = $('#applied-products-block');
      this.form = this.container.find('.quote-form').get(0);
      this.bodyNode = $('body');
      var self = this;
      var marketoFormId = isCladdingVersion ? 2703 : 1884;

      MktoForms2.loadForm("//app-ab07.marketo.com", "855-RSG-531", marketoFormId, function (marketoForm) {
        self.marketoForm = marketoForm;

        self.container.find('#new_leadproductinterest').attr('multiple', '');
        self.multiSelect = self.container.find('#new_leadproductinterest');

          if (marketoForm) {
               marketoForm.onSuccess(function () {
                  if (isCladdingVersion && isIframe()) {
                    var messageJson = JSON.stringify({ command: 'quoteSubmitted' });
                    parent.postMessage(messageJson, '*');
                  } else {
                    window.location = quoteSuccess;
                  }
          
                  return false;
                });
          }
       
      });
    },
    events: function () {
      var self = this;

      self.container.on('click', '.close', function () {
        self.container.fadeOut().promise().done(function () {
          self.bodyNode.removeClass('no-overflow');
        });
      });
    },
    createFormModel: function (applied, specifier, selector) {
      var self = this;
      var selections = _.map(applied, function (model) {
        var price = model.product.efficiency !== '' ? model.product.efficiency : null;
        return { id: model.product.id, surface: model.surfaceDisplay, type: model.productType, color: model.product.color, price: price };
      });

      var imageUrl = CHAM.RenderServer + 'getimage.ashx?specifier=' + encodeURIComponent(specifier)
        + (selector != null && selector !== '' ? '&sel=' + encodeURIComponent(selector) : '')
        + '&size=1000';

      var multiSelectValues = _(applied)
        .map(function (model) { return self.multiSelectLookup[model.productType] || self.multiSelectLookup[model.surfaceDisplay]; })
        .filter(function (value) { return value != null; })
            .value();

      return { selections: selections, imageUrl: imageUrl, multiSelect: multiSelectValues };
    },
    updateFormData: function (applied, specifier, selector) {
      this.form.reset();

      var model = this.createFormModel(applied, specifier, selector);
      this.marketoForm.addHiddenFields({
        selections: JSON.stringify(model.selections),
        imageUrl: model.imageUrl
      });

      this.multiSelect.val(model.multiSelect);
    },
    multiSelectLookup: {
      'Paramount Decking': 'Paramount Decking',
      'Symmetry Decking': 'Symmetry Decking',
      'Horizon Decking': 'Horizon Decking',
      'ProTect Advantage Decking': 'Protect Advantage Decking',
      'Good Life Decking': 'Good Life Decking',
      'Cladding': 'Composite Cladding',
    'Symmetry Railing': 'Symmetry Signature Railing',
    'Classic Square Railing': 'ArmorGuard Classic Railing',
    'Deluxe Square Railing': 'ArmorGuard Deluxe Railing',
    'Enclave Square Railing': 'ArmorGuard Enclave Railing',
    'Regency Square Railing': 'ArmorGuard Regency Railing',
      'Horizon Railing': 'Horizon Railing',
      'Post Lights': 'Side Post Lighting',
      'Riser Lights': 'Riser Lighting'
    }
  };
})(jQuery);