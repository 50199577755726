CHAM.compiled = { "scene": function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(scenes, function(scene, index) { ;
__p += '\r\n  <li class="' +
__e( index === 0 ? 'active' : '' ) +
'" data-index="' +
__e( index ) +
'">\r\n    <img src="//core2render.chameleonpower.com/cham/getImage.ashx?size=160&specifier=' +
__e( scene.specifier ) +
'" />\r\n  </li>\r\n';
 }); ;


}
return __p
},"menu/surface-menu": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul>\r\n    ';
 _.each(surfaceGroups, function(surfaceGroup) { ;
__p += '\r\n        <li class="surface-group" data-surfaces="' +
((__t = ( surfaceGroup.surfaceNumbers.join(',') )) == null ? '' : __t) +
'">\r\n            <div>' +
((__t = ( surfaceGroup.displayName )) == null ? '' : __t) +
'</div>\r\n            <i class="fa fa-chevron-right" aria-hidden="true"></i>\r\n        </li>\r\n    ';
 }); ;
__p += '\r\n</ul>';

}
return __p
},"products/applied": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul>\r\n    ';
 _.each(appliedModels, function (model) { ;
__p += '\r\n        <li>\r\n            <h5>' +
((__t = ( model.surfaceDisplay )) == null ? '' : __t) +
'</h5>\r\n            <div class="product-info">\r\n                <div>\r\n                    <div class="product-image ' +
((__t = ( model.surfaceType === 'Railing' || model.surfaceType === 'Lighting' ? 'contained' : 'covered' )) == null ? '' : __t) +
'"\r\n                         style="background-image: url(\'' +
((__t = ( model.thumbnailUrl )) == null ? '' : __t) +
'\');"></div>\r\n                </div>\r\n                <div class="product-specifics">\r\n                    <div>\r\n                        <h6>' +
((__t = ( model.productType )) == null ? '' : __t) +
'</h6>\r\n                        <p>' +
((__t = ( model.product.color )) == null ? '' : __t) +
'</p>\r\n                    </div>\r\n                    ';
 if (model.price != null) { ;
__p += '\r\n                        <h1>\r\n                            <sup class="dollar-sign">$</sup><span>' +
((__t = ( model.price.dollars )) == null ? '' : __t) +
'</span><sup>' +
((__t = ( model.price.cents )) == null ? '' : __t) +
'</sup><sub>/Linear Foot</sub>\r\n                        </h1>\r\n                    ';
 } ;
__p += '\r\n                </div>\r\n            </div>\r\n        </li>\r\n    ';
 }); ;
__p += '\r\n</ul>\r\n\r\n';
 if (appliedModels.length === 0) { ;
__p += '\r\n    <p>No applied products.</p>\r\n';
 } ;


}
return __p
},"products/blend-menu": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h4>MIX CLADDING COLORS</h4>\r\n<div class="blend-selected">\r\n  <div class="sixty">\r\n    <p>60%</p>\r\n    <div class="thumb-area ' +
((__t = ( appliedProducts.selectedSixty == true ? ' active' : '')) == null ? '' : __t) +
'">\r\n      <div class="selected ' +
((__t = ( appliedProducts.selectedSixty == true ? ' active' : '')) == null ? '' : __t) +
'">\r\n        \r\n        ';
 if (appliedProducts['60'] != null) { ;
__p += '\r\n        <img src="/img/products/' +
((__t = ( appliedProducts['60'].style )) == null ? '' : __t) +
'">\r\n        ';
 } ;
__p += '\r\n        ';
 if (appliedProducts['60'] == null) { ;
__p += '\r\n        <img src=\'../../img/wood-temp.png\' />\r\n        ';
 } ;
__p += '\r\n      </div>\r\n      <div class="select-color  ' +
((__t = ( appliedProducts['60'] == null ? ' active' : '')) == null ? '' : __t) +
'">\r\n        <img src="../../img/plus.svg"/>\r\n        <p>Select Color</p>\r\n      </div>\r\n    </div>\r\n    <p>' +
((__t = ( appliedProducts['60'] != null ? appliedProducts['60'].name : '' )) == null ? '' : __t) +
'</p>\r\n  </div>\r\n  <div class="switch">\r\n    <i class="fa fa-exchange"></i>\r\n  </div>\r\n  <div class="forty">\r\n    <p>40%</p>\r\n    <div class="thumb-area ' +
((__t = ( appliedProducts.selectedForty == true ? ' active' : '')) == null ? '' : __t) +
'">\r\n      <div class="selected ' +
((__t = ( appliedProducts.selectedForty == true ? ' active' : '')) == null ? '' : __t) +
'">\r\n        ';
 if (appliedProducts['40'] != null) { ;
__p += '\r\n        <img src="/img/products/' +
((__t = ( appliedProducts['40'].style )) == null ? '' : __t) +
'">\r\n        ';
 } ;
__p += '\r\n        ';
 if (appliedProducts['40'] == null) { ;
__p += '\r\n        <img src=\'../../img/wood-temp.png\' />\r\n        ';
 } ;
__p += '\r\n      </div>\r\n      <div class="select-color ' +
((__t = ( appliedProducts['40'] == null ? ' active' : '')) == null ? '' : __t) +
'"><img src="../../img/plus.svg"/>\r\n        <p>Select Color</p></div>\r\n    </div>\r\n    <p>' +
((__t = ( appliedProducts['40'] != null ? appliedProducts['40'].name : '' )) == null ? '' : __t) +
'</p>\r\n  </div>\r\n</div>\r\n<div class="remove">\r\n<p>Remove blend and close</p></div>';

}
return __p
},"products/deck": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="deck-selection">\r\n    <div class="deck-filter">\r\n        <p>Filter by:</p>\r\n        <p class="active tab-link" data-tab="1"><a>Product Style</a></p>\r\n        <p class="tab-link" data-tab="2"><a>Color Family</a></p>\r\n    </div>\r\n    <div class="deck-styles tab active" data-tab="1">\r\n        <form>\r\n            <select class="style-select">\r\n                <option value="All">All</option>\r\n                ';
 _.each(productStyleTab.filter, function (filterName) { ;
__p += '\r\n                    <option value="' +
((__t = ( filterName )) == null ? '' : __t) +
'">' +
((__t = ( filterName )) == null ? '' : __t) +
' Decking</option>\r\n                ';
 }); ;
__p += '\r\n            </select>\r\n        </form>\r\n        \r\n        <div class="decking-type-info"></div>\r\n\r\n        <div>\r\n            ';
 _.each(colorFamilyTab.products, function (productGroup) { ;
__p += '\r\n                <div class="product-group">\r\n                    <h5 class="title">' +
((__t = ( productGroup.name )) == null ? '' : __t) +
'</h5>\r\n                    <ul class="decking-options">\r\n                        ';
 _.each(productGroup.products, function (product) { ;
__p += '\r\n                            <li class="product" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n                                <div class="rect-thumb" style="background-image: url(\'/img/products/small/' +
((__t = ( product.style )) == null ? '' : __t) +
'\');">\r\n                                    <div class="zoomin ChamStats" data-csaction="zoom" data-csdata="1" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n                                        <i class="fa fa-search-plus" aria-hidden="true"></i>\r\n                                    </div>\r\n                                </div>\r\n                                <p>' +
((__t = ( product.color )) == null ? '' : __t) +
'</p>\r\n                            </li>\r\n                        ';
 }); ;
__p += '\r\n                    </ul>\r\n                </div>\r\n            ';
 }); ;
__p += '\r\n        </div>\r\n    </div>\r\n    <div class="deck-colors tab" data-tab="2">\r\n        <div id="deck-color-family">\r\n            <ul>\r\n                <li class="color-option all active no-select" data-filter="All">\r\n                    <div><div>All</div></div>\r\n                </li>\r\n                ';
 _.each(colorFamilyTab.filter, function (colorFamily) { ;
__p += '\r\n                    <li class="color-option" title="' +
((__t = ( colorFamily.name )) == null ? '' : __t) +
'" data-filter="' +
((__t = ( colorFamily.name )) == null ? '' : __t) +
'">\r\n                        <div style="background-color: ' +
((__t = ( colorFamily.hexColor )) == null ? '' : __t) +
';"></div>\r\n                    </li>\r\n                ';
 }); ;
__p += '\r\n            </ul>\r\n        </div>\r\n\r\n        <div>\r\n            ';
 _.each(colorFamilyTab.products, function (productGroup) { ;
__p += '\r\n                <div class="product-group">\r\n                    <h5>' +
((__t = ( productGroup.name )) == null ? '' : __t) +
'</h5>\r\n                    <ul class="decking-options-colorf">\r\n                      ';
 _.each(productGroup.products, function (product) { ;
__p += '\r\n                            <li class="product" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n                                <div class="rect-thumb-small" style="background-image: url(\'/img/products/small/' +
((__t = ( product.style )) == null ? '' : __t) +
'\');">\r\n                                    <div class="zoomin ChamStats" data-csaction="zoom" data-csdata="1" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n                                        <i class="fa fa-search-plus" aria-hidden="true"></i>\r\n                                    </div>\r\n                                </div>\r\n                                <p>' +
((__t = ( product.color )) == null ? '' : __t) +
'</p>\r\n                            </li>\r\n                        ';
 }); ;
__p += '\r\n                    </ul>\r\n                </div>\r\n            ';
 }); ;
__p += '\r\n        </div>\r\n    </div>\r\n</div>';

}
return __p
},"products/house-trim": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\r\n    <ul class="trim-options">\r\n        ';
 _.each(trimProducts, function (product) { ;
__p += '\r\n            <li class="product" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n                <div class="rect-thumb" style="background-image: url(\'//core2render.chameleonpower.com/cham/GetImage.ashx?type=productswatch&specifier=' +
((__t = ( encodeURIComponent(product.specifier) )) == null ? '' : __t) +
'&size=212\');"></div>\r\n                <p>' +
((__t = ( product.color )) == null ? '' : __t) +
'</p>\r\n            </li>\r\n        ';
 }); ;
__p += '\r\n    </ul>\r\n</div>';

}
return __p
},"products/lighting-current": function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="lighting-thumb" style="background-image: url(\'/img/products/' +
((__t = ( product.style )) == null ? '' : __t) +
'\');">\r\n    <div class="zoomin ChamStats" data-csaction="zoom" data-csdata="1" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n        <i class="fa fa-search-plus" aria-hidden="true"></i>\r\n    </div>\r\n</div>\r\n<p>' +
((__t = ( product.color )) == null ? '' : __t) +
'</p>';

}
return __p
},"products/lighting": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="lighting-selection">\r\n    <p>Select all lighting style and color options you prefer.</p>\r\n\r\n    ';
_.each(models, function (model) { ;
__p += '\r\n        <h5>' +
((__t = ( model.headerName )) == null ? '' : __t) +
'</h5>\r\n        <ul class="lighting-options">\r\n            <li class="light-type-holder">\r\n                <div class="lighting-thumb" style="background-image: url(\'/img/products/' +
((__t = ( model.products[0].style )) == null ? '' : __t) +
'\');">\r\n                    <div class="zoomin ChamStats" data-csaction="zoom" data-csdata="1" data-sel="' +
((__t = ( model.products[0].id )) == null ? '' : __t) +
'">\r\n                        <i class="fa fa-search-plus" aria-hidden="true"></i>\r\n                    </div>\r\n                </div>\r\n                <p>' +
((__t = ( model.products[0].color )) == null ? '' : __t) +
'</p>\r\n            </li>\r\n            <li class="light-color-options">\r\n                <ul class="light-color">\r\n                    ';
 _.each(model.products, function (product) {
                        var cssColor = convertCfxToCssColor(product.specifier); ;
__p += '\r\n                        <li class="product' +
((__t = ( cssColor == null ? ' bordered' : '' )) == null ? '' : __t) +
'" title="' +
((__t = ( product.color )) == null ? '' : __t) +
'"\r\n                            data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'"\r\n                            data-surface-name="' +
((__t = ( model.surfaceName )) == null ? '' : __t) +
'">\r\n                            <div style="background-color: ' +
((__t = ( cssColor || 'transparent' )) == null ? '' : __t) +
';"></div>\r\n                        </li>\r\n                    ';
 }); ;
__p += '\r\n                </ul>\r\n            </li>\r\n        </ul>\r\n    ';
 }); ;
__p += '\r\n</div>';

}
return __p
},"products/menu-wrapper": function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<li class="product-menu invisible" data-surfaces="' +
((__t = ( surfaceNumbers )) == null ? '' : __t) +
'">\r\n    <div class="close" data-surfaces="' +
((__t = ( surfaceNumbers )) == null ? '' : __t) +
'">\r\n        <span>Close</span><img src="/img/close.svg" />\r\n    </div>\r\n    <h2 class="menu-header">' +
((__t = ( displayName )) == null ? '' : __t) +
'</h2>\r\n    <div class="menu-content">\r\n        ' +
((__t = ( bodyHtml )) == null ? '' : __t) +
'\r\n    </div>\r\n</li>';

}
return __p
},"products/railing-current": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="railing railing-thumb" style="background-image: url(\'/img/products/' +
((__t = ( product.style )) == null ? '' : __t) +
'\');">\r\n    ';
 if (product.warranty != null && product.warranty !== '') { ;
__p += '\r\n        <div class="zoomin ChamStats" data-csaction="zoom" data-csdata="1" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n            <i class="fa fa-search-plus" aria-hidden="true"></i>\r\n        </div>\r\n    ';
 } ;
__p += '\r\n</div>\r\n<p>' +
((__t = ( product.color )) == null ? '' : __t) +
'</p>';

}
return __p
},"products/railing": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

//console.log('railing temp', railingModels);
__p += '\r\n<div class="railing-selection">\r\n    <div class="railing-steps">\r\n        <p class="rail-link active" data-step="1">Step One</p>\r\n        <p class="rail-link" data-step="2">Step Two</p>\r\n    </div>\r\n    <div id="step-one" class="rail-step active" data-step="1">\r\n        ';
 _.each(railingModels, function (model) { ;
__p += '\r\n            <h3>' +
((__t = ( model.railingType )) == null ? '' : __t) +
' Railing</h3>\r\n\r\n            ';
 if (model.info != null) { ;
__p += '\r\n                <div class="rail-info">\r\n                    <h4>' +
((__t = ( model.info.header )) == null ? '' : __t) +
'</h4>\r\n                    <p>' +
((__t = ( model.info.body )) == null ? '' : __t) +
'</p>\r\n                </div>\r\n            ';
 } ;
__p += '\r\n\r\n            <ul class="railing-options">\r\n                <li class="current-product">\r\n                    ' +
((__t = ( getCurrentProductHtml(model.products[0]) )) == null ? '' : __t) +
'\r\n                </li>\r\n                <li class="rail-color-options">\r\n                    <ul class="railing-color">\r\n                        ';
 _.each(model.products, function (product) { 
                            var cssColor = convertCfxToCssColor(product.specifier); ;
__p += '\r\n                            <li class="railing-product' +
((__t = ( cssColor == null ? ' bordered' : '' )) == null ? '' : __t) +
'" title="' +
((__t = ( product.color )) == null ? '' : __t) +
'"\r\n                                data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'"\r\n                                data-surface-names="' +
((__t = ( railingSurfaceNames )) == null ? '' : __t) +
'"\r\n                                data-railing-type="' +
((__t = ( model.railingType )) == null ? '' : __t) +
'">\r\n                                <div style="background-color: ' +
((__t = ( cssColor || 'transparent' )) == null ? '' : __t) +
';"></div>\r\n                            </li>\r\n                        ';
 }); ;
__p += '\r\n                    </ul>\r\n                </li>\r\n            </ul>\r\n        ';
 }); ;
__p += '\r\n\r\n        <div class="centered">\r\n            <div id="railing-next">Next</div>\r\n        </div>\r\n    </div>\r\n  \r\n  <div id="step-two" class="rail-step" data-step="2">\r\n    ';
_.each(additionalOptions, function (optionModels, railingType) { ;
__p += '\r\n    <div class="railing-additional" data-railing-type="' +
((__t = ( railingType )) == null ? '' : __t) +
'">\r\n      ';
 _.each(optionModels, function (model) { ;
__p += '\r\n\r\n      <h5>' +
((__t = ( model.headerName )) == null ? '' : __t) +
'</h5>\r\n      <ul class="additional-options">\r\n        <li class="railing-type-holder current-product">\r\n          ' +
((__t = ( getCurrentProductHtml(model.products[0]) )) == null ? '' : __t) +
'\r\n        </li>\r\n        <li class="rail-color-options2">\r\n          <ul class="railing-color">\r\n            ';
 _.each(model.products, function (product) {
            var cssColor = convertCfxToCssColor(product.specifier); ;
__p += '\r\n            <li class="product' +
((__t = ( cssColor == null ? ' bordered' : '' )) == null ? '' : __t) +
'" title="' +
((__t = ( product.color )) == null ? '' : __t) +
'"\r\n                data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'"\r\n                data-surface-names="' +
((__t = ( model.surfaceNames )) == null ? '' : __t) +
'">\r\n              <div style="background-color: ' +
((__t = (cssColor||'transparent')) == null ? '' : __t) +
';"></div>\r\n            </li>\r\n            ';
 }); ;
__p += '\r\n          </ul>\r\n        </li>\r\n      </ul>\r\n      ';
 }); ;
__p += '\r\n    </div>\r\n    ';
 }); ;
__p += '\r\n  </div>\r\n</div>';

}
return __p
},"products/wall": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="wall-selection">\r\n  <div id="wall-btns">\r\n    <div class="wall-filter">\r\n      <p>Filter by:</p>\r\n      <p class="tab-link active" data-tab="1"><a>Wildwood Cladding</a></p>\r\n      <p class="tab-link" data-tab="2"><a>Siding and Brick</a></p>\r\n    </div>\r\n\r\n    <div id="blend" class="ChamStats" data-csaction="blend" data-csdata="1">\r\n      <span>MIX CLADDING COLORS</span>\r\n    </div>\r\n  </div>\r\n  <div class="blend-clad">\r\n  </div>\r\n\r\n  <div id="wall-fiberon-cladding" class="tab active" data-tab="1">\r\n    <div class="wall-type-info">\r\n      <h4>Unique capped composite warranted for rain screen applications</h4>\r\n      <p>\r\n        Can be installed vertically or horizontally. Deflects water and adds aesthetic appeal.\r\n        Never needs painting, staining or refinishing so long term costs are lower than other cladding material. Easy to install.\r\n      </p>\r\n    </div>\r\n\r\n    ';
 if (claddingProducts.length === 0) { ;
__p += '\r\n    <p>Coming soon.</p>\r\n    ';
 } ;
__p += '\r\n\r\n    <ul class="wall-options">\r\n      ';
 _.each(claddingProducts, function (product) { ;
__p += '\r\n      <li class="product" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n        <div class="rect-thumb" style="background-image: url(\'/img/products/small/' +
((__t = ( product.style )) == null ? '' : __t) +
'\');">\r\n          <div class="zoomin ChamStats" data-csaction="zoom" data-csdata="1" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n            <i class="fa fa-search-plus" aria-hidden="true"></i>\r\n          </div>\r\n        </div>\r\n        <p>' +
((__t = ( product.color )) == null ? '' : __t) +
'</p>\r\n      </li>\r\n      ';
 }); ;
__p += '\r\n    </ul>\r\n  </div>\r\n  <div id="siding-brick" class="tab" data-tab="2">\r\n    <ul class="wall-options">\r\n      ';
 _.each(genericProducts, function (product) { ;
__p += '\r\n      <li class="product" data-sel="' +
((__t = ( product.id )) == null ? '' : __t) +
'">\r\n        <div class="rect-thumb" style="background-image: url(\'//core2render.chameleonpower.com/cham/GetImage.ashx?type=productswatch&specifier=' +
((__t = ( encodeURIComponent(product.specifier) )) == null ? '' : __t) +
'&size=212\');"></div>\r\n        <p>' +
((__t = ( product.color )) == null ? '' : __t) +
'</p>\r\n      </li>\r\n      ';
 }); ;
__p += '\r\n    </ul>\r\n  </div>\r\n</div>';

}
return __p
},"zoom/default-zoom": function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="product-photo" style="background-image: url(\'/img/products/' +
((__t = ( product.style )) == null ? '' : __t) +
'\');"></div>\r\n<div class="product-details">\r\n    <h3>' +
((__t = ( title )) == null ? '' : __t) +
'</h3>\r\n    <p>' +
((__t = ( product.warranty )) == null ? '' : __t) +
'</p>\r\n</div>';

}
return __p
},"zoom/wide-zoom": function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="railing-photo">\r\n    <img src="/img/products/' +
((__t = ( product.style )) == null ? '' : __t) +
'" />\r\n</div>\r\n<div class="product-details">\r\n    <h3>' +
((__t = ( title )) == null ? '' : __t) +
'</h3>\r\n\r\n    ';
 if (descriptionItems.length > 1) { ;
__p += '\r\n        <ul>\r\n            ';
 _.each(descriptionItems, function (item) { ;
__p += '\r\n                <li>' +
((__t = ( item )) == null ? '' : __t) +
'</li>\r\n            ';
 }); ;
__p += '\r\n        </ul>\r\n    ';
 } else { ;
__p += '\r\n        <p>' +
((__t = ( descriptionItems[0] )) == null ? '' : __t) +
'</p>\r\n    ';
 } ;
__p += '\r\n</div>';

}
return __p
} };