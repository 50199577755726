var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  CHAM.tour = {
    currentIndex: 0,
    steps: [
      // Define steps here
      //{ element: '#surface-tour', target: '', attachment: '', targetAttachment: '' },
      //{ element: '#product-tour', target: '', attachment: '', targetAttachment: '' },
      //{ element: '#visualizer-tour', target: '', attachment: '', targetAttachment: '' },
      //{ element: '#menu-tour', target: '', attachment: '', targetAttachment: '' },
      //{ element: '#footer-tour', target: '', attachment: '', targetAttachment: '' }
    ],

    init: function () {
      var tourNode = $('#tour');
      if (tourNode.length === 0)
        return;

      var self = this;
      var stepNodes = tourNode.find('.tour-step');
      var activeSteps = _.map(this.steps, function (stepConfig) {
        var stepNode = stepNodes.filter(stepConfig.element);

        return {
          node: stepNode,
          tether: self.createTether(stepNode, stepConfig)
        };
      });

      this.tourOverlay = tourNode;
      this.stepNodes = stepNodes;
      this.activeSteps = activeSteps;
    },
    events: function () {
      var self = this;

      var endTour = function () {
        var current = self.activeSteps[self.currentIndex];
        if (current.node.is(':animated'))
          return;

        self.currentIndex = 0;
        current.node.fadeOut();
        self.tourOverlay.hide();
      };

      this.stepNodes.on('click', '.close', endTour);
      this.tourOverlay.on('click', endTour);

      this.stepNodes.on('click', '.next', function () {
        var current = self.activeSteps[self.currentIndex];
        if (current.node.is(':animated'))
          return;

        current.node.fadeOut().promise().done(function () {
          var next = self.activeSteps[++self.currentIndex];
          self.showStep(next.node, next.tether);
        });
      });

      $('#tour-btn').on('click', function () {
        var current = self.activeSteps[self.currentIndex];
        self.tourOverlay.show();
        self.showStep(current.node, current.tether);
      });
    },
    createTether: function (stepNode, config) {
      return new Tether({
        element: stepNode.get(0),
        target: $(config.target).get(0),
        attachment: config.attachment,
        targetAttachment: config.targetAttachment,
        constraints: [{ to: 'window', pin: true }]
      });
    },
    showStep: function (stepNode, tether) {
      // Elements with display: none; are not rendered at all. We must render the element with 0 opacity then reposition before fading in.
      stepNode.css({ display: 'block', opacity: 0 });
      tether.position();
      stepNode.animate({ opacity: 1 });
    }
  };
})(jQuery);
