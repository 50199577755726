//projects.js manages projects saved by registered users
var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var $main = $('#MAIN');

  CHAM.projects = {
    init: function () {
      var self = this;
      this.root = $('#my-projects');
      this.root.MyProjects({
        siteid: CHAM.SiteID,
        modalWidth: 600,
        Draggable: false,
        IntegratedIM: true,
        ThumbnailSize: 230,
        MyProjectsHTML: '/MyProjects/MyProjectsHTML.ashx',
        MyProjectsUtils: '/MyProjects/MyProjectsUtils.ashx',
        MyProjectsBaseCSS: '/css/my-projects.css',
        UseProjectHandler: function (specifier, selector, srid, imid, imid3, action, aux1) {
          var url = CHAM.helpers.createVisualizerUrl(specifier, selector);

          if (action == 'im' && imid3 != void 0 && imid3 != '') {
            window.location.href = '/im.aspx?specifier=' + specifier;
          }
          else window.location = url;
          window.setTimeout(function () {
            self.root.MyProjects({ action: 'close' });
          }, 100);
        }
      });
    },
    events: function () {
      var self = this;
      $main.on('click', '#save img, #save span',function () {
        var specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
        var selector = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
        self.root.MyProjects({ action: 'showSave', specifier: specifier, sel: selector });
      });
      $main.on('click', '#projects, #sign-in', function () {
        self.root.MyProjects({ action: 'open' });
        kenshoo_conv('signup', '', '', '', 'USD');
      });
      $main.on('click', '#landing-projects', function () {
        self.root.MyProjects({ action: 'open' });
      });
    }
  };
})(jQuery);