//menu.js displays products menu
var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var lazy = Utilities.lazy;
  var $sidebar = $('#side-menu');
  var headerBar = $('#header-bar');

  CHAM.menu = {
    isHeaderOpen: false,
    init: function () {
      this.bodyNode = $('body');
      this.productMenus = lazy(function () {
        var surfaceNav = $('#surface-menu .surface-group');

        return _.map($('.product-menu').get(), function (element) {
          var node = $(element);
          var surfaceNumbers = node.data('surfaces').toString();
          var navNode = surfaceNav.filter('[data-surfaces="' + surfaceNumbers + '"]');

          return { navNode: navNode, productNode: node, surfaceNumbers: surfaceNumbers.split(','), isActive: false };
        });
      });
    },
      //opens products menu
    toggleSurfaceGroup: function (surfaceNumbers) {
      var menus = this.productMenus.value;

      for (var index = 0; index < menus.length; index++) {
        var menu = menus[index];
        var wasActive = menu.isActive;

        menu.isActive = !menu.isActive && _.some(surfaceNumbers, function (surfaceNumber) {
          return menu.surfaceNumbers.indexOf(surfaceNumber) > -1;
        });

        menu.navNode.toggleClass('active', menu.isActive);

        if (menu.isActive) {
          menu.productNode.removeClass('invisible').addClass('active');
          this.bodyNode.addClass('no-overflow');
        } else if (wasActive)
          this.hideSurfaceMenu(menu.productNode);
      }
    },
      //close products menu
    closeSurfaceGroup: function (surfaceNumbers) {
      var menus = this.productMenus.value;
      var surfaceMenu = _.find(menus, function (menu) {
        return _.some(surfaceNumbers, function (surfaceNumber) {
          return menu.surfaceNumbers.indexOf(surfaceNumber) > -1;
        });
      });

      surfaceMenu.isActive = false;
      surfaceMenu.navNode.toggleClass('active', surfaceMenu.isActive);
      this.hideSurfaceMenu(surfaceMenu.productNode);
    },
    hideSurfaceMenu: function (productNode) {
      // The only purpose of the invisible class is to prevent a transition from occuring when switching to/from mobile breakpoints.
      var self = this;
      
      productNode.removeClass('active');
      productNode.one(CHAM.transitionEnd, function () {
        self.bodyNode.removeClass('no-overflow');
        productNode.addClass('invisible');

        productNode.off(CHAM.transitionEnd); // Must remove, will fire twice in chrome.
      });
    },
    render: function (surfaceGroups) {
      var menu = CHAM.templates.menu(surfaceGroups);
      document.getElementById('surface-menu').innerHTML = menu;
    },
    home: function () {
      CHAM.router.navigate('/');
    },
    events: function () {
      var self = this;

      $sidebar.on('click', '.surface-group', function () {
        var surfaceNumbers = $(this).data('surfaces').toString().split(',');
        self.toggleSurfaceGroup(surfaceNumbers);
      });

      $sidebar.on('click', '#home', self.home.bind(this));

      $sidebar.on('click', '#print img, #print span', function () {
        var specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
        var selector = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
        var url = CHAM.CurrentDomain
          + '/print.aspx?specifier=' + encodeURIComponent(specifier)
          + (selector != null && selector !== '' ? '&sel=' + encodeURIComponent(selector) : '');
        window.open(url);
      });

      headerBar.on('click', '#header-stack i', function () {
        self.isHeaderOpen = !self.isHeaderOpen;
        headerBar.toggleClass('active', self.isHeaderOpen);
      });

      var headerElement = headerBar.get(0);

      $(document).on('click', function (e) {
        if (!self.isHeaderOpen || $(e.target).is(headerElement) || $.contains(headerElement, e.target))
          return;

        headerBar.removeClass('active');
        self.isHeaderOpen = false;
      });
    }
  };
})(jQuery);