var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function($) {
  'use strict';

  var $body = $('body');

  CHAM.events = {
    productHighlighted: Observable()
  }
  CHAM.initialize = {
    init: function init() {
      CHAM.helpers.polyfills();
      this.data();
      this.render();
      this.events();
    },
    bootstrap: function bootstrap() {
      this.cookie();
      $body.addClass(Utilities.isSmallMobileDevice() ? 'mobile' : 'non-mobile');
    },
    cookie: function cookie() {
      Cookies.set('SiteID', CHAM.SiteID);
      Cookies.set('SiteId', CHAM.SiteID);
      if (Cookies.get('SiteID') === void 0 || Cookies.get('SiteId') === void 0 ) {
        alertify.alert(CHAM.CookieMessage);
      }
    },
    data: function data() {
      CHAM.scenes.data();
      CHAM.products.data();
    },
    render: function render() {
      CHAM.projects.init();
      CHAM.feedback.init();
      CHAM.email.init();
      CHAM.upload.init();
      CHAM.helpers.initShareLink();
      CHAM.applied.init();
      CHAM.quote.init();
    },
    events: function events() {
      CHAM.projects.events();
      CHAM.feedback.events();
      CHAM.email.events();
      CHAM.upload.events();
      CHAM.menu.events();
      CHAM.landing.events();
      CHAM.scenes.events();
      CHAM.visualizer.events();
      CHAM.products.events();
      CHAM.quote.events();
    },
    router: function router() {
      CHAM.router = new Navigo(null, true);
      CHAM.router.on({
        'scene/:spec/products/:sel': function (params) {
          var specifier = decodeURIComponent(params.spec);
          var selector = decodeURIComponent(params.sel);
          CHAM.visualizer.init(specifier, selector);
        },
        '*': function () {
          CHAM.scenes.render();
        }
      }).resolve();
    },
    surfacesLoaded: function (surfaces) {
      var surfaceGroups = _(surfaces)
        .groupBy(function (surface) {
          var isGroupable = surface.type === 'Railing' || surface.type === 'Lighting';
          return isGroupable ? surface.type : surface.name;
        })
        .map(function (surfaces, groupName) {
           var surfaceNumbers = _.map(surfaces, function (surface) { return surface.specifiersurfacenumber; });
          var surfaceType = surfaces[0].type;
          return { displayName: groupName, surfaces: surfaces, surfaceNumbers: surfaceNumbers, surfaceType: surfaceType };
        })
        .value();
      CHAM.menu.init();
      CHAM.menu.render(surfaceGroups);
      CHAM.products.render(surfaceGroups);
      CHAM.applied.events(surfaces);
      CHAM.share.init();
    },
    AllSurfacesLoaded: function () {
      CHAM.visualizer.updateURL();
      CHAM.share.update();
      CHAM.share.events();
    },
    initComplete: function () {
      // Called in visualizer.js
      CHAM.share.init();
    },
    lazyLoad: function lazyLoad() {
      window.setTimeout(function() {
        var nds = document.querySelectorAll('.lazy');
        for (var i = 0, n; n = nds[i++];) {
          window.LazyLoad(n, false);
        }
      }, 1000);
    },
  };

  CHAM.documentOnReady = {
    init: function init() {
      CHAM.initialize.init();
      CHAM.initialize.router();
    }
  };

  CHAM.documentOnLoad = {
    init: function init() {
      CHAM.initialize.bootstrap();
    }
  };

  if ($('body.page-default').length > 0) {
    $(document).ready(CHAM.documentOnReady.init);
    $(window).on('load', CHAM.documentOnLoad.init);
  }
})(jQuery);
